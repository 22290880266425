/* Not using base and components because Ionic offers it's own */

/*
  @import "tailwindcss/base";
  @import "tailwindcss/components";
*/

/* If needed add custom Tailwind component classes before your utilities */

.space-y-0 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(0px * var(--space-y-reverse)) !important;
}

.space-x-0 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(0px * var(--space-x-reverse)) !important;
  margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-1 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
}

.space-x-1 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
  margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-2 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
}

.space-x-2 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-3 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
}

.space-x-3 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
  margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-4 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
}

.space-x-4 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(1rem * var(--space-x-reverse)) !important;
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-5 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
}

.space-x-5 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
  margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-6 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
}

.space-x-6 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-8 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
}

.space-x-8 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(2rem * var(--space-x-reverse)) !important;
  margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-10 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
}

.space-x-10 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-12 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
}

.space-x-12 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(3rem * var(--space-x-reverse)) !important;
  margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-16 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
}

.space-x-16 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(4rem * var(--space-x-reverse)) !important;
  margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-20 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
}

.space-x-20 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(5rem * var(--space-x-reverse)) !important;
  margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-24 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
}

.space-x-24 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(6rem * var(--space-x-reverse)) !important;
  margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-32 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
}

.space-x-32 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(8rem * var(--space-x-reverse)) !important;
  margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-36 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
}

.space-x-36 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(9rem * var(--space-x-reverse)) !important;
  margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-40 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
}

.space-x-40 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(10rem * var(--space-x-reverse)) !important;
  margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-48 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
}

.space-x-48 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(12rem * var(--space-x-reverse)) !important;
  margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-56 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
}

.space-x-56 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(14rem * var(--space-x-reverse)) !important;
  margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-64 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
}

.space-x-64 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(16rem * var(--space-x-reverse)) !important;
  margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-px > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1px * var(--space-y-reverse)) !important;
}

.space-x-px > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(1px * var(--space-x-reverse)) !important;
  margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-clanScreenEdge > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(1.428rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1.428rem * var(--space-y-reverse)) !important;
}

.space-x-clanScreenEdge > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(1.428rem * var(--space-x-reverse)) !important;
  margin-left: calc(1.428rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-clanFlowStepScreenEdge > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(1.563rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1.563rem * var(--space-y-reverse)) !important;
}

.space-x-clanFlowStepScreenEdge > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(1.563rem * var(--space-x-reverse)) !important;
  margin-left: calc(1.563rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-clanCard > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(32rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(32rem * var(--space-y-reverse)) !important;
}

.space-x-clanCard > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(32rem * var(--space-x-reverse)) !important;
  margin-left: calc(32rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-clanBtn > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
}

.space-x-clanBtn > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-clanBtnFat > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
}

.space-x-clanBtnFat > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-clanAvatar > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(12.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(12.5rem * var(--space-y-reverse)) !important;
}

.space-x-clanAvatar > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(12.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(12.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-clanProfileAvatar > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
}

.space-x-clanProfileAvatar > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(8rem * var(--space-x-reverse)) !important;
  margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-1 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
}

.-space-x-1 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
  margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-2 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
}

.-space-x-2 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-3 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
}

.-space-x-3 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
  margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-4 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
}

.-space-x-4 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-1rem * var(--space-x-reverse)) !important;
  margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-5 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
}

.-space-x-5 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
  margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-6 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
}

.-space-x-6 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-8 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
}

.-space-x-8 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-2rem * var(--space-x-reverse)) !important;
  margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-10 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
}

.-space-x-10 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-12 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
}

.-space-x-12 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-3rem * var(--space-x-reverse)) !important;
  margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-16 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
}

.-space-x-16 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-4rem * var(--space-x-reverse)) !important;
  margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-20 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
}

.-space-x-20 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-24 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
}

.-space-x-24 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-6rem * var(--space-x-reverse)) !important;
  margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-32 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
}

.-space-x-32 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-8rem * var(--space-x-reverse)) !important;
  margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-36 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
}

.-space-x-36 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-9rem * var(--space-x-reverse)) !important;
  margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-40 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
}

.-space-x-40 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-10rem * var(--space-x-reverse)) !important;
  margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-48 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
}

.-space-x-48 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-12rem * var(--space-x-reverse)) !important;
  margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-56 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
}

.-space-x-56 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-14rem * var(--space-x-reverse)) !important;
  margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-64 > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
}

.-space-x-64 > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-16rem * var(--space-x-reverse)) !important;
  margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-px > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
}

.-space-x-px > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-1px * var(--space-x-reverse)) !important;
  margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-clanScreenEdge > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-1.428rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1.428rem * var(--space-y-reverse)) !important;
}

.-space-x-clanScreenEdge > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-1.428rem * var(--space-x-reverse)) !important;
  margin-left: calc(-1.428rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-clanFlowStepScreenEdge > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-1.563rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1.563rem * var(--space-y-reverse)) !important;
}

.-space-x-clanFlowStepScreenEdge > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-1.563rem * var(--space-x-reverse)) !important;
  margin-left: calc(-1.563rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-clanCard > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-32rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-32rem * var(--space-y-reverse)) !important;
}

.-space-x-clanCard > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-32rem * var(--space-x-reverse)) !important;
  margin-left: calc(-32rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-clanBtn > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
}

.-space-x-clanBtn > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-clanBtnFat > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
}

.-space-x-clanBtnFat > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-clanAvatar > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-12.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-12.5rem * var(--space-y-reverse)) !important;
}

.-space-x-clanAvatar > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-12.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-12.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-clanProfileAvatar > :not(template) ~ :not(template){
  --space-y-reverse: 0 !important;
  margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
}

.-space-x-clanProfileAvatar > :not(template) ~ :not(template){
  --space-x-reverse: 0 !important;
  margin-right: calc(-8rem * var(--space-x-reverse)) !important;
  margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-reverse > :not(template) ~ :not(template){
  --space-y-reverse: 1 !important;
}

.space-x-reverse > :not(template) ~ :not(template){
  --space-x-reverse: 1 !important;
}

.divide-y-0 > :not(template) ~ :not(template){
  --divide-y-reverse: 0 !important;
  border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
}

.divide-x-0 > :not(template) ~ :not(template){
  --divide-x-reverse: 0 !important;
  border-right-width: calc(0px * var(--divide-x-reverse)) !important;
  border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y-2 > :not(template) ~ :not(template){
  --divide-y-reverse: 0 !important;
  border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
}

.divide-x-2 > :not(template) ~ :not(template){
  --divide-x-reverse: 0 !important;
  border-right-width: calc(2px * var(--divide-x-reverse)) !important;
  border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y-4 > :not(template) ~ :not(template){
  --divide-y-reverse: 0 !important;
  border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
}

.divide-x-4 > :not(template) ~ :not(template){
  --divide-x-reverse: 0 !important;
  border-right-width: calc(4px * var(--divide-x-reverse)) !important;
  border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y-8 > :not(template) ~ :not(template){
  --divide-y-reverse: 0 !important;
  border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
}

.divide-x-8 > :not(template) ~ :not(template){
  --divide-x-reverse: 0 !important;
  border-right-width: calc(8px * var(--divide-x-reverse)) !important;
  border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y > :not(template) ~ :not(template){
  --divide-y-reverse: 0 !important;
  border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
}

.divide-x > :not(template) ~ :not(template){
  --divide-x-reverse: 0 !important;
  border-right-width: calc(1px * var(--divide-x-reverse)) !important;
  border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y-reverse > :not(template) ~ :not(template){
  --divide-y-reverse: 1 !important;
}

.divide-x-reverse > :not(template) ~ :not(template){
  --divide-x-reverse: 1 !important;
}

.divide-transparent > :not(template) ~ :not(template){
  border-color: transparent !important;
}

.divide-current > :not(template) ~ :not(template){
  border-color: currentColor !important;
}

.divide-black > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #000 !important;
  border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
}

.divide-white > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fff !important;
  border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
}

.divide-gray-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #f7fafc !important;
  border-color: rgba(247, 250, 252, var(--divide-opacity)) !important;
}

.divide-gray-200 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #edf2f7 !important;
  border-color: rgba(237, 242, 247, var(--divide-opacity)) !important;
}

.divide-gray-300 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #e2e8f0 !important;
  border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
}

.divide-gray-400 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #cbd5e0 !important;
  border-color: rgba(203, 213, 224, var(--divide-opacity)) !important;
}

.divide-gray-500 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #a0aec0 !important;
  border-color: rgba(160, 174, 192, var(--divide-opacity)) !important;
}

.divide-gray-600 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #718096 !important;
  border-color: rgba(113, 128, 150, var(--divide-opacity)) !important;
}

.divide-gray-700 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #4a5568 !important;
  border-color: rgba(74, 85, 104, var(--divide-opacity)) !important;
}

.divide-gray-800 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #2d3748 !important;
  border-color: rgba(45, 55, 72, var(--divide-opacity)) !important;
}

.divide-gray-900 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #1a202c !important;
  border-color: rgba(26, 32, 44, var(--divide-opacity)) !important;
}

.divide-red-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fff5f5 !important;
  border-color: rgba(255, 245, 245, var(--divide-opacity)) !important;
}

.divide-red-200 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fed7d7 !important;
  border-color: rgba(254, 215, 215, var(--divide-opacity)) !important;
}

.divide-red-300 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #feb2b2 !important;
  border-color: rgba(254, 178, 178, var(--divide-opacity)) !important;
}

.divide-red-400 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fc8181 !important;
  border-color: rgba(252, 129, 129, var(--divide-opacity)) !important;
}

.divide-red-500 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #f56565 !important;
  border-color: rgba(245, 101, 101, var(--divide-opacity)) !important;
}

.divide-red-600 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #e53e3e !important;
  border-color: rgba(229, 62, 62, var(--divide-opacity)) !important;
}

.divide-red-700 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #c53030 !important;
  border-color: rgba(197, 48, 48, var(--divide-opacity)) !important;
}

.divide-red-800 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #9b2c2c !important;
  border-color: rgba(155, 44, 44, var(--divide-opacity)) !important;
}

.divide-red-900 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #742a2a !important;
  border-color: rgba(116, 42, 42, var(--divide-opacity)) !important;
}

.divide-orange-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fffaf0 !important;
  border-color: rgba(255, 250, 240, var(--divide-opacity)) !important;
}

.divide-orange-200 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #feebc8 !important;
  border-color: rgba(254, 235, 200, var(--divide-opacity)) !important;
}

.divide-orange-300 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fbd38d !important;
  border-color: rgba(251, 211, 141, var(--divide-opacity)) !important;
}

.divide-orange-400 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #f6ad55 !important;
  border-color: rgba(246, 173, 85, var(--divide-opacity)) !important;
}

.divide-orange-500 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #ed8936 !important;
  border-color: rgba(237, 137, 54, var(--divide-opacity)) !important;
}

.divide-orange-600 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #dd6b20 !important;
  border-color: rgba(221, 107, 32, var(--divide-opacity)) !important;
}

.divide-orange-700 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #c05621 !important;
  border-color: rgba(192, 86, 33, var(--divide-opacity)) !important;
}

.divide-orange-800 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #9c4221 !important;
  border-color: rgba(156, 66, 33, var(--divide-opacity)) !important;
}

.divide-orange-900 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #7b341e !important;
  border-color: rgba(123, 52, 30, var(--divide-opacity)) !important;
}

.divide-yellow-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fffff0 !important;
  border-color: rgba(255, 255, 240, var(--divide-opacity)) !important;
}

.divide-yellow-200 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fefcbf !important;
  border-color: rgba(254, 252, 191, var(--divide-opacity)) !important;
}

.divide-yellow-300 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #faf089 !important;
  border-color: rgba(250, 240, 137, var(--divide-opacity)) !important;
}

.divide-yellow-400 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #f6e05e !important;
  border-color: rgba(246, 224, 94, var(--divide-opacity)) !important;
}

.divide-yellow-500 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #ecc94b !important;
  border-color: rgba(236, 201, 75, var(--divide-opacity)) !important;
}

.divide-yellow-600 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #d69e2e !important;
  border-color: rgba(214, 158, 46, var(--divide-opacity)) !important;
}

.divide-yellow-700 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #b7791f !important;
  border-color: rgba(183, 121, 31, var(--divide-opacity)) !important;
}

.divide-yellow-800 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #975a16 !important;
  border-color: rgba(151, 90, 22, var(--divide-opacity)) !important;
}

.divide-yellow-900 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #744210 !important;
  border-color: rgba(116, 66, 16, var(--divide-opacity)) !important;
}

.divide-green-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #f0fff4 !important;
  border-color: rgba(240, 255, 244, var(--divide-opacity)) !important;
}

.divide-green-200 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #c6f6d5 !important;
  border-color: rgba(198, 246, 213, var(--divide-opacity)) !important;
}

.divide-green-300 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #9ae6b4 !important;
  border-color: rgba(154, 230, 180, var(--divide-opacity)) !important;
}

.divide-green-400 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #68d391 !important;
  border-color: rgba(104, 211, 145, var(--divide-opacity)) !important;
}

.divide-green-500 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #48bb78 !important;
  border-color: rgba(72, 187, 120, var(--divide-opacity)) !important;
}

.divide-green-600 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #38a169 !important;
  border-color: rgba(56, 161, 105, var(--divide-opacity)) !important;
}

.divide-green-700 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #2f855a !important;
  border-color: rgba(47, 133, 90, var(--divide-opacity)) !important;
}

.divide-green-800 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #276749 !important;
  border-color: rgba(39, 103, 73, var(--divide-opacity)) !important;
}

.divide-green-900 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #22543d !important;
  border-color: rgba(34, 84, 61, var(--divide-opacity)) !important;
}

.divide-teal-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #e6fffa !important;
  border-color: rgba(230, 255, 250, var(--divide-opacity)) !important;
}

.divide-teal-200 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #b2f5ea !important;
  border-color: rgba(178, 245, 234, var(--divide-opacity)) !important;
}

.divide-teal-300 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #81e6d9 !important;
  border-color: rgba(129, 230, 217, var(--divide-opacity)) !important;
}

.divide-teal-400 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #4fd1c5 !important;
  border-color: rgba(79, 209, 197, var(--divide-opacity)) !important;
}

.divide-teal-500 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #38b2ac !important;
  border-color: rgba(56, 178, 172, var(--divide-opacity)) !important;
}

.divide-teal-600 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #319795 !important;
  border-color: rgba(49, 151, 149, var(--divide-opacity)) !important;
}

.divide-teal-700 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #2c7a7b !important;
  border-color: rgba(44, 122, 123, var(--divide-opacity)) !important;
}

.divide-teal-800 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #285e61 !important;
  border-color: rgba(40, 94, 97, var(--divide-opacity)) !important;
}

.divide-teal-900 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #234e52 !important;
  border-color: rgba(35, 78, 82, var(--divide-opacity)) !important;
}

.divide-blue-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #ebf8ff !important;
  border-color: rgba(235, 248, 255, var(--divide-opacity)) !important;
}

.divide-blue-200 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #bee3f8 !important;
  border-color: rgba(190, 227, 248, var(--divide-opacity)) !important;
}

.divide-blue-300 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #90cdf4 !important;
  border-color: rgba(144, 205, 244, var(--divide-opacity)) !important;
}

.divide-blue-400 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #63b3ed !important;
  border-color: rgba(99, 179, 237, var(--divide-opacity)) !important;
}

.divide-blue-500 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #4299e1 !important;
  border-color: rgba(66, 153, 225, var(--divide-opacity)) !important;
}

.divide-blue-600 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #3182ce !important;
  border-color: rgba(49, 130, 206, var(--divide-opacity)) !important;
}

.divide-blue-700 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #2b6cb0 !important;
  border-color: rgba(43, 108, 176, var(--divide-opacity)) !important;
}

.divide-blue-800 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #2c5282 !important;
  border-color: rgba(44, 82, 130, var(--divide-opacity)) !important;
}

.divide-blue-900 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #2a4365 !important;
  border-color: rgba(42, 67, 101, var(--divide-opacity)) !important;
}

.divide-indigo-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #ebf4ff !important;
  border-color: rgba(235, 244, 255, var(--divide-opacity)) !important;
}

.divide-indigo-200 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #c3dafe !important;
  border-color: rgba(195, 218, 254, var(--divide-opacity)) !important;
}

.divide-indigo-300 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #a3bffa !important;
  border-color: rgba(163, 191, 250, var(--divide-opacity)) !important;
}

.divide-indigo-400 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #7f9cf5 !important;
  border-color: rgba(127, 156, 245, var(--divide-opacity)) !important;
}

.divide-indigo-500 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #667eea !important;
  border-color: rgba(102, 126, 234, var(--divide-opacity)) !important;
}

.divide-indigo-600 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #5a67d8 !important;
  border-color: rgba(90, 103, 216, var(--divide-opacity)) !important;
}

.divide-indigo-700 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #4c51bf !important;
  border-color: rgba(76, 81, 191, var(--divide-opacity)) !important;
}

.divide-indigo-800 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #434190 !important;
  border-color: rgba(67, 65, 144, var(--divide-opacity)) !important;
}

.divide-indigo-900 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #3c366b !important;
  border-color: rgba(60, 54, 107, var(--divide-opacity)) !important;
}

.divide-purple-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #faf5ff !important;
  border-color: rgba(250, 245, 255, var(--divide-opacity)) !important;
}

.divide-purple-200 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #e9d8fd !important;
  border-color: rgba(233, 216, 253, var(--divide-opacity)) !important;
}

.divide-purple-300 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #d6bcfa !important;
  border-color: rgba(214, 188, 250, var(--divide-opacity)) !important;
}

.divide-purple-400 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #b794f4 !important;
  border-color: rgba(183, 148, 244, var(--divide-opacity)) !important;
}

.divide-purple-500 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #9f7aea !important;
  border-color: rgba(159, 122, 234, var(--divide-opacity)) !important;
}

.divide-purple-600 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #805ad5 !important;
  border-color: rgba(128, 90, 213, var(--divide-opacity)) !important;
}

.divide-purple-700 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #6b46c1 !important;
  border-color: rgba(107, 70, 193, var(--divide-opacity)) !important;
}

.divide-purple-800 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #553c9a !important;
  border-color: rgba(85, 60, 154, var(--divide-opacity)) !important;
}

.divide-purple-900 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #44337a !important;
  border-color: rgba(68, 51, 122, var(--divide-opacity)) !important;
}

.divide-pink-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fff5f7 !important;
  border-color: rgba(255, 245, 247, var(--divide-opacity)) !important;
}

.divide-pink-200 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fed7e2 !important;
  border-color: rgba(254, 215, 226, var(--divide-opacity)) !important;
}

.divide-pink-300 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fbb6ce !important;
  border-color: rgba(251, 182, 206, var(--divide-opacity)) !important;
}

.divide-pink-400 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #f687b3 !important;
  border-color: rgba(246, 135, 179, var(--divide-opacity)) !important;
}

.divide-pink-500 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #ed64a6 !important;
  border-color: rgba(237, 100, 166, var(--divide-opacity)) !important;
}

.divide-pink-600 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #d53f8c !important;
  border-color: rgba(213, 63, 140, var(--divide-opacity)) !important;
}

.divide-pink-700 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #b83280 !important;
  border-color: rgba(184, 50, 128, var(--divide-opacity)) !important;
}

.divide-pink-800 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #97266d !important;
  border-color: rgba(151, 38, 109, var(--divide-opacity)) !important;
}

.divide-pink-900 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #702459 !important;
  border-color: rgba(112, 36, 89, var(--divide-opacity)) !important;
}

.divide-clanGreen-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #45dd85 !important;
  border-color: rgba(69, 221, 133, var(--divide-opacity)) !important;
}

.divide-clanGray-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fafafa !important;
  border-color: rgba(250, 250, 250, var(--divide-opacity)) !important;
}

.divide-clanGray-200 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #878396 !important;
  border-color: rgba(135, 131, 150, var(--divide-opacity)) !important;
}

.divide-clanGray-300 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #e0e0e0 !important;
  border-color: rgba(224, 224, 224, var(--divide-opacity)) !important;
}

.divide-clanYellow-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #fee661 !important;
  border-color: rgba(254, 230, 97, var(--divide-opacity)) !important;
}

.divide-clanRed-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
  border-color: #ff6471 !important;
  border-color: rgba(255, 100, 113, var(--divide-opacity)) !important;
}

.divide-solid > :not(template) ~ :not(template){
  border-style: solid !important;
}

.divide-dashed > :not(template) ~ :not(template){
  border-style: dashed !important;
}

.divide-dotted > :not(template) ~ :not(template){
  border-style: dotted !important;
}

.divide-double > :not(template) ~ :not(template){
  border-style: double !important;
}

.divide-none > :not(template) ~ :not(template){
  border-style: none !important;
}

.divide-opacity-0 > :not(template) ~ :not(template){
  --divide-opacity: 0 !important;
}

.divide-opacity-25 > :not(template) ~ :not(template){
  --divide-opacity: 0.25 !important;
}

.divide-opacity-50 > :not(template) ~ :not(template){
  --divide-opacity: 0.5 !important;
}

.divide-opacity-75 > :not(template) ~ :not(template){
  --divide-opacity: 0.75 !important;
}

.divide-opacity-100 > :not(template) ~ :not(template){
  --divide-opacity: 1 !important;
}

.sr-only{
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.not-sr-only{
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.appearance-none{
  -webkit-appearance: none !important;
          appearance: none !important;
}

.bg-fixed{
  background-attachment: fixed !important;
}

.bg-local{
  background-attachment: local !important;
}

.bg-scroll{
  background-attachment: scroll !important;
}

.bg-clip-border{
  background-clip: border-box !important;
}

.bg-clip-padding{
  background-clip: padding-box !important;
}

.bg-clip-content{
  background-clip: content-box !important;
}

.bg-clip-text{
  -webkit-background-clip: text !important;
          background-clip: text !important;
}

.bg-transparent{
  background-color: transparent !important;
}

.bg-current{
  background-color: currentColor !important;
}

.bg-black{
  --bg-opacity: 1 !important;
  background-color: #000 !important;
  background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
}

.bg-white{
  --bg-opacity: 1 !important;
  background-color: #fff !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
}

.bg-gray-100{
  --bg-opacity: 1 !important;
  background-color: #f7fafc !important;
  background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
}

.bg-gray-200{
  --bg-opacity: 1 !important;
  background-color: #edf2f7 !important;
  background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
}

.bg-gray-300{
  --bg-opacity: 1 !important;
  background-color: #e2e8f0 !important;
  background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
}

.bg-gray-400{
  --bg-opacity: 1 !important;
  background-color: #cbd5e0 !important;
  background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
}

.bg-gray-500{
  --bg-opacity: 1 !important;
  background-color: #a0aec0 !important;
  background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
}

.bg-gray-600{
  --bg-opacity: 1 !important;
  background-color: #718096 !important;
  background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
}

.bg-gray-700{
  --bg-opacity: 1 !important;
  background-color: #4a5568 !important;
  background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
}

.bg-gray-800{
  --bg-opacity: 1 !important;
  background-color: #2d3748 !important;
  background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
}

.bg-gray-900{
  --bg-opacity: 1 !important;
  background-color: #1a202c !important;
  background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
}

.bg-red-100{
  --bg-opacity: 1 !important;
  background-color: #fff5f5 !important;
  background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
}

.bg-red-200{
  --bg-opacity: 1 !important;
  background-color: #fed7d7 !important;
  background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
}

.bg-red-300{
  --bg-opacity: 1 !important;
  background-color: #feb2b2 !important;
  background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
}

.bg-red-400{
  --bg-opacity: 1 !important;
  background-color: #fc8181 !important;
  background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
}

.bg-red-500{
  --bg-opacity: 1 !important;
  background-color: #f56565 !important;
  background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
}

.bg-red-600{
  --bg-opacity: 1 !important;
  background-color: #e53e3e !important;
  background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
}

.bg-red-700{
  --bg-opacity: 1 !important;
  background-color: #c53030 !important;
  background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
}

.bg-red-800{
  --bg-opacity: 1 !important;
  background-color: #9b2c2c !important;
  background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
}

.bg-red-900{
  --bg-opacity: 1 !important;
  background-color: #742a2a !important;
  background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
}

.bg-orange-100{
  --bg-opacity: 1 !important;
  background-color: #fffaf0 !important;
  background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
}

.bg-orange-200{
  --bg-opacity: 1 !important;
  background-color: #feebc8 !important;
  background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
}

.bg-orange-300{
  --bg-opacity: 1 !important;
  background-color: #fbd38d !important;
  background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
}

.bg-orange-400{
  --bg-opacity: 1 !important;
  background-color: #f6ad55 !important;
  background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
}

.bg-orange-500{
  --bg-opacity: 1 !important;
  background-color: #ed8936 !important;
  background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
}

.bg-orange-600{
  --bg-opacity: 1 !important;
  background-color: #dd6b20 !important;
  background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
}

.bg-orange-700{
  --bg-opacity: 1 !important;
  background-color: #c05621 !important;
  background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
}

.bg-orange-800{
  --bg-opacity: 1 !important;
  background-color: #9c4221 !important;
  background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
}

.bg-orange-900{
  --bg-opacity: 1 !important;
  background-color: #7b341e !important;
  background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
}

.bg-yellow-100{
  --bg-opacity: 1 !important;
  background-color: #fffff0 !important;
  background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
}

.bg-yellow-200{
  --bg-opacity: 1 !important;
  background-color: #fefcbf !important;
  background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
}

.bg-yellow-300{
  --bg-opacity: 1 !important;
  background-color: #faf089 !important;
  background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
}

.bg-yellow-400{
  --bg-opacity: 1 !important;
  background-color: #f6e05e !important;
  background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
}

.bg-yellow-500{
  --bg-opacity: 1 !important;
  background-color: #ecc94b !important;
  background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
}

.bg-yellow-600{
  --bg-opacity: 1 !important;
  background-color: #d69e2e !important;
  background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
}

.bg-yellow-700{
  --bg-opacity: 1 !important;
  background-color: #b7791f !important;
  background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
}

.bg-yellow-800{
  --bg-opacity: 1 !important;
  background-color: #975a16 !important;
  background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
}

.bg-yellow-900{
  --bg-opacity: 1 !important;
  background-color: #744210 !important;
  background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
}

.bg-green-100{
  --bg-opacity: 1 !important;
  background-color: #f0fff4 !important;
  background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
}

.bg-green-200{
  --bg-opacity: 1 !important;
  background-color: #c6f6d5 !important;
  background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
}

.bg-green-300{
  --bg-opacity: 1 !important;
  background-color: #9ae6b4 !important;
  background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
}

.bg-green-400{
  --bg-opacity: 1 !important;
  background-color: #68d391 !important;
  background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
}

.bg-green-500{
  --bg-opacity: 1 !important;
  background-color: #48bb78 !important;
  background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
}

.bg-green-600{
  --bg-opacity: 1 !important;
  background-color: #38a169 !important;
  background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
}

.bg-green-700{
  --bg-opacity: 1 !important;
  background-color: #2f855a !important;
  background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
}

.bg-green-800{
  --bg-opacity: 1 !important;
  background-color: #276749 !important;
  background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
}

.bg-green-900{
  --bg-opacity: 1 !important;
  background-color: #22543d !important;
  background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
}

.bg-teal-100{
  --bg-opacity: 1 !important;
  background-color: #e6fffa !important;
  background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
}

.bg-teal-200{
  --bg-opacity: 1 !important;
  background-color: #b2f5ea !important;
  background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
}

.bg-teal-300{
  --bg-opacity: 1 !important;
  background-color: #81e6d9 !important;
  background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
}

.bg-teal-400{
  --bg-opacity: 1 !important;
  background-color: #4fd1c5 !important;
  background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
}

.bg-teal-500{
  --bg-opacity: 1 !important;
  background-color: #38b2ac !important;
  background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
}

.bg-teal-600{
  --bg-opacity: 1 !important;
  background-color: #319795 !important;
  background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
}

.bg-teal-700{
  --bg-opacity: 1 !important;
  background-color: #2c7a7b !important;
  background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
}

.bg-teal-800{
  --bg-opacity: 1 !important;
  background-color: #285e61 !important;
  background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
}

.bg-teal-900{
  --bg-opacity: 1 !important;
  background-color: #234e52 !important;
  background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
}

.bg-blue-100{
  --bg-opacity: 1 !important;
  background-color: #ebf8ff !important;
  background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
}

.bg-blue-200{
  --bg-opacity: 1 !important;
  background-color: #bee3f8 !important;
  background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
}

.bg-blue-300{
  --bg-opacity: 1 !important;
  background-color: #90cdf4 !important;
  background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
}

.bg-blue-400{
  --bg-opacity: 1 !important;
  background-color: #63b3ed !important;
  background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
}

.bg-blue-500{
  --bg-opacity: 1 !important;
  background-color: #4299e1 !important;
  background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
}

.bg-blue-600{
  --bg-opacity: 1 !important;
  background-color: #3182ce !important;
  background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
}

.bg-blue-700{
  --bg-opacity: 1 !important;
  background-color: #2b6cb0 !important;
  background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
}

.bg-blue-800{
  --bg-opacity: 1 !important;
  background-color: #2c5282 !important;
  background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
}

.bg-blue-900{
  --bg-opacity: 1 !important;
  background-color: #2a4365 !important;
  background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
}

.bg-indigo-100{
  --bg-opacity: 1 !important;
  background-color: #ebf4ff !important;
  background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
}

.bg-indigo-200{
  --bg-opacity: 1 !important;
  background-color: #c3dafe !important;
  background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
}

.bg-indigo-300{
  --bg-opacity: 1 !important;
  background-color: #a3bffa !important;
  background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
}

.bg-indigo-400{
  --bg-opacity: 1 !important;
  background-color: #7f9cf5 !important;
  background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
}

.bg-indigo-500{
  --bg-opacity: 1 !important;
  background-color: #667eea !important;
  background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
}

.bg-indigo-600{
  --bg-opacity: 1 !important;
  background-color: #5a67d8 !important;
  background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
}

.bg-indigo-700{
  --bg-opacity: 1 !important;
  background-color: #4c51bf !important;
  background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
}

.bg-indigo-800{
  --bg-opacity: 1 !important;
  background-color: #434190 !important;
  background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
}

.bg-indigo-900{
  --bg-opacity: 1 !important;
  background-color: #3c366b !important;
  background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
}

.bg-purple-100{
  --bg-opacity: 1 !important;
  background-color: #faf5ff !important;
  background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
}

.bg-purple-200{
  --bg-opacity: 1 !important;
  background-color: #e9d8fd !important;
  background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
}

.bg-purple-300{
  --bg-opacity: 1 !important;
  background-color: #d6bcfa !important;
  background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
}

.bg-purple-400{
  --bg-opacity: 1 !important;
  background-color: #b794f4 !important;
  background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
}

.bg-purple-500{
  --bg-opacity: 1 !important;
  background-color: #9f7aea !important;
  background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
}

.bg-purple-600{
  --bg-opacity: 1 !important;
  background-color: #805ad5 !important;
  background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
}

.bg-purple-700{
  --bg-opacity: 1 !important;
  background-color: #6b46c1 !important;
  background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
}

.bg-purple-800{
  --bg-opacity: 1 !important;
  background-color: #553c9a !important;
  background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
}

.bg-purple-900{
  --bg-opacity: 1 !important;
  background-color: #44337a !important;
  background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
}

.bg-pink-100{
  --bg-opacity: 1 !important;
  background-color: #fff5f7 !important;
  background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
}

.bg-pink-200{
  --bg-opacity: 1 !important;
  background-color: #fed7e2 !important;
  background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
}

.bg-pink-300{
  --bg-opacity: 1 !important;
  background-color: #fbb6ce !important;
  background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
}

.bg-pink-400{
  --bg-opacity: 1 !important;
  background-color: #f687b3 !important;
  background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
}

.bg-pink-500{
  --bg-opacity: 1 !important;
  background-color: #ed64a6 !important;
  background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
}

.bg-pink-600{
  --bg-opacity: 1 !important;
  background-color: #d53f8c !important;
  background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
}

.bg-pink-700{
  --bg-opacity: 1 !important;
  background-color: #b83280 !important;
  background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
}

.bg-pink-800{
  --bg-opacity: 1 !important;
  background-color: #97266d !important;
  background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
}

.bg-pink-900{
  --bg-opacity: 1 !important;
  background-color: #702459 !important;
  background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
}

.bg-clanGreen-100{
  --bg-opacity: 1 !important;
  background-color: #45dd85 !important;
  background-color: rgba(69, 221, 133, var(--bg-opacity)) !important;
}

.bg-clanGray-100{
  --bg-opacity: 1 !important;
  background-color: #fafafa !important;
  background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
}

.bg-clanGray-200{
  --bg-opacity: 1 !important;
  background-color: #878396 !important;
  background-color: rgba(135, 131, 150, var(--bg-opacity)) !important;
}

.bg-clanGray-300{
  --bg-opacity: 1 !important;
  background-color: #e0e0e0 !important;
  background-color: rgba(224, 224, 224, var(--bg-opacity)) !important;
}

.bg-clanYellow-100{
  --bg-opacity: 1 !important;
  background-color: #fee661 !important;
  background-color: rgba(254, 230, 97, var(--bg-opacity)) !important;
}

.bg-clanRed-100{
  --bg-opacity: 1 !important;
  background-color: #ff6471 !important;
  background-color: rgba(255, 100, 113, var(--bg-opacity)) !important;
}

.bg-none{
  background-image: none !important;
}

.bg-gradient-to-t{
  background-image: linear-gradient(to top, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-tr{
  background-image: linear-gradient(to top right, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-r{
  background-image: linear-gradient(to right, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-br{
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-b{
  background-image: linear-gradient(to bottom, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-bl{
  background-image: linear-gradient(to bottom left, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-l{
  background-image: linear-gradient(to left, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-tl{
  background-image: linear-gradient(to top left, var(--gradient-color-stops)) !important;
}

.from-transparent{
  --gradient-from-color: transparent !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.from-current{
  --gradient-from-color: currentColor !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.from-black{
  --gradient-from-color: #000 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.from-white{
  --gradient-from-color: #fff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.from-gray-100{
  --gradient-from-color: #f7fafc !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
}

.from-gray-200{
  --gradient-from-color: #edf2f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
}

.from-gray-300{
  --gradient-from-color: #e2e8f0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
}

.from-gray-400{
  --gradient-from-color: #cbd5e0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
}

.from-gray-500{
  --gradient-from-color: #a0aec0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
}

.from-gray-600{
  --gradient-from-color: #718096 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
}

.from-gray-700{
  --gradient-from-color: #4a5568 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
}

.from-gray-800{
  --gradient-from-color: #2d3748 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
}

.from-gray-900{
  --gradient-from-color: #1a202c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
}

.from-red-100{
  --gradient-from-color: #fff5f5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
}

.from-red-200{
  --gradient-from-color: #fed7d7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
}

.from-red-300{
  --gradient-from-color: #feb2b2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
}

.from-red-400{
  --gradient-from-color: #fc8181 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
}

.from-red-500{
  --gradient-from-color: #f56565 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
}

.from-red-600{
  --gradient-from-color: #e53e3e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
}

.from-red-700{
  --gradient-from-color: #c53030 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
}

.from-red-800{
  --gradient-from-color: #9b2c2c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
}

.from-red-900{
  --gradient-from-color: #742a2a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
}

.from-orange-100{
  --gradient-from-color: #fffaf0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
}

.from-orange-200{
  --gradient-from-color: #feebc8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
}

.from-orange-300{
  --gradient-from-color: #fbd38d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
}

.from-orange-400{
  --gradient-from-color: #f6ad55 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
}

.from-orange-500{
  --gradient-from-color: #ed8936 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
}

.from-orange-600{
  --gradient-from-color: #dd6b20 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
}

.from-orange-700{
  --gradient-from-color: #c05621 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
}

.from-orange-800{
  --gradient-from-color: #9c4221 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
}

.from-orange-900{
  --gradient-from-color: #7b341e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
}

.from-yellow-100{
  --gradient-from-color: #fffff0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
}

.from-yellow-200{
  --gradient-from-color: #fefcbf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
}

.from-yellow-300{
  --gradient-from-color: #faf089 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
}

.from-yellow-400{
  --gradient-from-color: #f6e05e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
}

.from-yellow-500{
  --gradient-from-color: #ecc94b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
}

.from-yellow-600{
  --gradient-from-color: #d69e2e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
}

.from-yellow-700{
  --gradient-from-color: #b7791f !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
}

.from-yellow-800{
  --gradient-from-color: #975a16 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
}

.from-yellow-900{
  --gradient-from-color: #744210 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
}

.from-green-100{
  --gradient-from-color: #f0fff4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
}

.from-green-200{
  --gradient-from-color: #c6f6d5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
}

.from-green-300{
  --gradient-from-color: #9ae6b4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
}

.from-green-400{
  --gradient-from-color: #68d391 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
}

.from-green-500{
  --gradient-from-color: #48bb78 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
}

.from-green-600{
  --gradient-from-color: #38a169 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
}

.from-green-700{
  --gradient-from-color: #2f855a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
}

.from-green-800{
  --gradient-from-color: #276749 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
}

.from-green-900{
  --gradient-from-color: #22543d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
}

.from-teal-100{
  --gradient-from-color: #e6fffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
}

.from-teal-200{
  --gradient-from-color: #b2f5ea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
}

.from-teal-300{
  --gradient-from-color: #81e6d9 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
}

.from-teal-400{
  --gradient-from-color: #4fd1c5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
}

.from-teal-500{
  --gradient-from-color: #38b2ac !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
}

.from-teal-600{
  --gradient-from-color: #319795 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
}

.from-teal-700{
  --gradient-from-color: #2c7a7b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
}

.from-teal-800{
  --gradient-from-color: #285e61 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
}

.from-teal-900{
  --gradient-from-color: #234e52 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
}

.from-blue-100{
  --gradient-from-color: #ebf8ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
}

.from-blue-200{
  --gradient-from-color: #bee3f8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
}

.from-blue-300{
  --gradient-from-color: #90cdf4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
}

.from-blue-400{
  --gradient-from-color: #63b3ed !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
}

.from-blue-500{
  --gradient-from-color: #4299e1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
}

.from-blue-600{
  --gradient-from-color: #3182ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
}

.from-blue-700{
  --gradient-from-color: #2b6cb0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
}

.from-blue-800{
  --gradient-from-color: #2c5282 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
}

.from-blue-900{
  --gradient-from-color: #2a4365 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
}

.from-indigo-100{
  --gradient-from-color: #ebf4ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
}

.from-indigo-200{
  --gradient-from-color: #c3dafe !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
}

.from-indigo-300{
  --gradient-from-color: #a3bffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
}

.from-indigo-400{
  --gradient-from-color: #7f9cf5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
}

.from-indigo-500{
  --gradient-from-color: #667eea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
}

.from-indigo-600{
  --gradient-from-color: #5a67d8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
}

.from-indigo-700{
  --gradient-from-color: #4c51bf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
}

.from-indigo-800{
  --gradient-from-color: #434190 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
}

.from-indigo-900{
  --gradient-from-color: #3c366b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
}

.from-purple-100{
  --gradient-from-color: #faf5ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
}

.from-purple-200{
  --gradient-from-color: #e9d8fd !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
}

.from-purple-300{
  --gradient-from-color: #d6bcfa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
}

.from-purple-400{
  --gradient-from-color: #b794f4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
}

.from-purple-500{
  --gradient-from-color: #9f7aea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
}

.from-purple-600{
  --gradient-from-color: #805ad5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
}

.from-purple-700{
  --gradient-from-color: #6b46c1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
}

.from-purple-800{
  --gradient-from-color: #553c9a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
}

.from-purple-900{
  --gradient-from-color: #44337a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
}

.from-pink-100{
  --gradient-from-color: #fff5f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
}

.from-pink-200{
  --gradient-from-color: #fed7e2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
}

.from-pink-300{
  --gradient-from-color: #fbb6ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
}

.from-pink-400{
  --gradient-from-color: #f687b3 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
}

.from-pink-500{
  --gradient-from-color: #ed64a6 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
}

.from-pink-600{
  --gradient-from-color: #d53f8c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
}

.from-pink-700{
  --gradient-from-color: #b83280 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
}

.from-pink-800{
  --gradient-from-color: #97266d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
}

.from-pink-900{
  --gradient-from-color: #702459 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
}

.from-clanGreen-100{
  --gradient-from-color: #45dd85 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(69, 221, 133, 0)) !important;
}

.from-clanGray-100{
  --gradient-from-color: #fafafa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
}

.from-clanGray-200{
  --gradient-from-color: #878396 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(135, 131, 150, 0)) !important;
}

.from-clanGray-300{
  --gradient-from-color: #e0e0e0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(224, 224, 224, 0)) !important;
}

.from-clanYellow-100{
  --gradient-from-color: #fee661 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 230, 97, 0)) !important;
}

.from-clanRed-100{
  --gradient-from-color: #ff6471 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 100, 113, 0)) !important;
}

.from-clanYellowFaded{
  --gradient-from-color: #FBFA8D !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 250, 141, 0)) !important;
}

.via-transparent{
  --gradient-via-color: transparent !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.via-current{
  --gradient-via-color: currentColor !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.via-black{
  --gradient-via-color: #000 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.via-white{
  --gradient-via-color: #fff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.via-gray-100{
  --gradient-via-color: #f7fafc !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
}

.via-gray-200{
  --gradient-via-color: #edf2f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
}

.via-gray-300{
  --gradient-via-color: #e2e8f0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
}

.via-gray-400{
  --gradient-via-color: #cbd5e0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
}

.via-gray-500{
  --gradient-via-color: #a0aec0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
}

.via-gray-600{
  --gradient-via-color: #718096 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
}

.via-gray-700{
  --gradient-via-color: #4a5568 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
}

.via-gray-800{
  --gradient-via-color: #2d3748 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
}

.via-gray-900{
  --gradient-via-color: #1a202c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
}

.via-red-100{
  --gradient-via-color: #fff5f5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
}

.via-red-200{
  --gradient-via-color: #fed7d7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
}

.via-red-300{
  --gradient-via-color: #feb2b2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
}

.via-red-400{
  --gradient-via-color: #fc8181 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
}

.via-red-500{
  --gradient-via-color: #f56565 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
}

.via-red-600{
  --gradient-via-color: #e53e3e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
}

.via-red-700{
  --gradient-via-color: #c53030 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
}

.via-red-800{
  --gradient-via-color: #9b2c2c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
}

.via-red-900{
  --gradient-via-color: #742a2a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
}

.via-orange-100{
  --gradient-via-color: #fffaf0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
}

.via-orange-200{
  --gradient-via-color: #feebc8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
}

.via-orange-300{
  --gradient-via-color: #fbd38d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
}

.via-orange-400{
  --gradient-via-color: #f6ad55 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
}

.via-orange-500{
  --gradient-via-color: #ed8936 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
}

.via-orange-600{
  --gradient-via-color: #dd6b20 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
}

.via-orange-700{
  --gradient-via-color: #c05621 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
}

.via-orange-800{
  --gradient-via-color: #9c4221 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
}

.via-orange-900{
  --gradient-via-color: #7b341e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
}

.via-yellow-100{
  --gradient-via-color: #fffff0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
}

.via-yellow-200{
  --gradient-via-color: #fefcbf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
}

.via-yellow-300{
  --gradient-via-color: #faf089 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
}

.via-yellow-400{
  --gradient-via-color: #f6e05e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
}

.via-yellow-500{
  --gradient-via-color: #ecc94b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
}

.via-yellow-600{
  --gradient-via-color: #d69e2e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
}

.via-yellow-700{
  --gradient-via-color: #b7791f !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
}

.via-yellow-800{
  --gradient-via-color: #975a16 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
}

.via-yellow-900{
  --gradient-via-color: #744210 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
}

.via-green-100{
  --gradient-via-color: #f0fff4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
}

.via-green-200{
  --gradient-via-color: #c6f6d5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
}

.via-green-300{
  --gradient-via-color: #9ae6b4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
}

.via-green-400{
  --gradient-via-color: #68d391 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
}

.via-green-500{
  --gradient-via-color: #48bb78 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
}

.via-green-600{
  --gradient-via-color: #38a169 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
}

.via-green-700{
  --gradient-via-color: #2f855a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
}

.via-green-800{
  --gradient-via-color: #276749 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
}

.via-green-900{
  --gradient-via-color: #22543d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
}

.via-teal-100{
  --gradient-via-color: #e6fffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
}

.via-teal-200{
  --gradient-via-color: #b2f5ea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
}

.via-teal-300{
  --gradient-via-color: #81e6d9 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
}

.via-teal-400{
  --gradient-via-color: #4fd1c5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
}

.via-teal-500{
  --gradient-via-color: #38b2ac !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
}

.via-teal-600{
  --gradient-via-color: #319795 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
}

.via-teal-700{
  --gradient-via-color: #2c7a7b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
}

.via-teal-800{
  --gradient-via-color: #285e61 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
}

.via-teal-900{
  --gradient-via-color: #234e52 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
}

.via-blue-100{
  --gradient-via-color: #ebf8ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
}

.via-blue-200{
  --gradient-via-color: #bee3f8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
}

.via-blue-300{
  --gradient-via-color: #90cdf4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
}

.via-blue-400{
  --gradient-via-color: #63b3ed !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
}

.via-blue-500{
  --gradient-via-color: #4299e1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
}

.via-blue-600{
  --gradient-via-color: #3182ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
}

.via-blue-700{
  --gradient-via-color: #2b6cb0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
}

.via-blue-800{
  --gradient-via-color: #2c5282 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
}

.via-blue-900{
  --gradient-via-color: #2a4365 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
}

.via-indigo-100{
  --gradient-via-color: #ebf4ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
}

.via-indigo-200{
  --gradient-via-color: #c3dafe !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
}

.via-indigo-300{
  --gradient-via-color: #a3bffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
}

.via-indigo-400{
  --gradient-via-color: #7f9cf5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
}

.via-indigo-500{
  --gradient-via-color: #667eea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
}

.via-indigo-600{
  --gradient-via-color: #5a67d8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
}

.via-indigo-700{
  --gradient-via-color: #4c51bf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
}

.via-indigo-800{
  --gradient-via-color: #434190 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
}

.via-indigo-900{
  --gradient-via-color: #3c366b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
}

.via-purple-100{
  --gradient-via-color: #faf5ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
}

.via-purple-200{
  --gradient-via-color: #e9d8fd !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
}

.via-purple-300{
  --gradient-via-color: #d6bcfa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
}

.via-purple-400{
  --gradient-via-color: #b794f4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
}

.via-purple-500{
  --gradient-via-color: #9f7aea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
}

.via-purple-600{
  --gradient-via-color: #805ad5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
}

.via-purple-700{
  --gradient-via-color: #6b46c1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
}

.via-purple-800{
  --gradient-via-color: #553c9a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
}

.via-purple-900{
  --gradient-via-color: #44337a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
}

.via-pink-100{
  --gradient-via-color: #fff5f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
}

.via-pink-200{
  --gradient-via-color: #fed7e2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
}

.via-pink-300{
  --gradient-via-color: #fbb6ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
}

.via-pink-400{
  --gradient-via-color: #f687b3 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
}

.via-pink-500{
  --gradient-via-color: #ed64a6 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
}

.via-pink-600{
  --gradient-via-color: #d53f8c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
}

.via-pink-700{
  --gradient-via-color: #b83280 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
}

.via-pink-800{
  --gradient-via-color: #97266d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
}

.via-pink-900{
  --gradient-via-color: #702459 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
}

.via-clanGreen-100{
  --gradient-via-color: #45dd85 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(69, 221, 133, 0)) !important;
}

.via-clanGray-100{
  --gradient-via-color: #fafafa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
}

.via-clanGray-200{
  --gradient-via-color: #878396 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(135, 131, 150, 0)) !important;
}

.via-clanGray-300{
  --gradient-via-color: #e0e0e0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(224, 224, 224, 0)) !important;
}

.via-clanYellow-100{
  --gradient-via-color: #fee661 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 230, 97, 0)) !important;
}

.via-clanRed-100{
  --gradient-via-color: #ff6471 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 100, 113, 0)) !important;
}

.via-clanYellowFaded{
  --gradient-via-color: #FBFA8D !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 250, 141, 0)) !important;
}

.to-transparent{
  --gradient-to-color: transparent !important;
}

.to-current{
  --gradient-to-color: currentColor !important;
}

.to-black{
  --gradient-to-color: #000 !important;
}

.to-white{
  --gradient-to-color: #fff !important;
}

.to-gray-100{
  --gradient-to-color: #f7fafc !important;
}

.to-gray-200{
  --gradient-to-color: #edf2f7 !important;
}

.to-gray-300{
  --gradient-to-color: #e2e8f0 !important;
}

.to-gray-400{
  --gradient-to-color: #cbd5e0 !important;
}

.to-gray-500{
  --gradient-to-color: #a0aec0 !important;
}

.to-gray-600{
  --gradient-to-color: #718096 !important;
}

.to-gray-700{
  --gradient-to-color: #4a5568 !important;
}

.to-gray-800{
  --gradient-to-color: #2d3748 !important;
}

.to-gray-900{
  --gradient-to-color: #1a202c !important;
}

.to-red-100{
  --gradient-to-color: #fff5f5 !important;
}

.to-red-200{
  --gradient-to-color: #fed7d7 !important;
}

.to-red-300{
  --gradient-to-color: #feb2b2 !important;
}

.to-red-400{
  --gradient-to-color: #fc8181 !important;
}

.to-red-500{
  --gradient-to-color: #f56565 !important;
}

.to-red-600{
  --gradient-to-color: #e53e3e !important;
}

.to-red-700{
  --gradient-to-color: #c53030 !important;
}

.to-red-800{
  --gradient-to-color: #9b2c2c !important;
}

.to-red-900{
  --gradient-to-color: #742a2a !important;
}

.to-orange-100{
  --gradient-to-color: #fffaf0 !important;
}

.to-orange-200{
  --gradient-to-color: #feebc8 !important;
}

.to-orange-300{
  --gradient-to-color: #fbd38d !important;
}

.to-orange-400{
  --gradient-to-color: #f6ad55 !important;
}

.to-orange-500{
  --gradient-to-color: #ed8936 !important;
}

.to-orange-600{
  --gradient-to-color: #dd6b20 !important;
}

.to-orange-700{
  --gradient-to-color: #c05621 !important;
}

.to-orange-800{
  --gradient-to-color: #9c4221 !important;
}

.to-orange-900{
  --gradient-to-color: #7b341e !important;
}

.to-yellow-100{
  --gradient-to-color: #fffff0 !important;
}

.to-yellow-200{
  --gradient-to-color: #fefcbf !important;
}

.to-yellow-300{
  --gradient-to-color: #faf089 !important;
}

.to-yellow-400{
  --gradient-to-color: #f6e05e !important;
}

.to-yellow-500{
  --gradient-to-color: #ecc94b !important;
}

.to-yellow-600{
  --gradient-to-color: #d69e2e !important;
}

.to-yellow-700{
  --gradient-to-color: #b7791f !important;
}

.to-yellow-800{
  --gradient-to-color: #975a16 !important;
}

.to-yellow-900{
  --gradient-to-color: #744210 !important;
}

.to-green-100{
  --gradient-to-color: #f0fff4 !important;
}

.to-green-200{
  --gradient-to-color: #c6f6d5 !important;
}

.to-green-300{
  --gradient-to-color: #9ae6b4 !important;
}

.to-green-400{
  --gradient-to-color: #68d391 !important;
}

.to-green-500{
  --gradient-to-color: #48bb78 !important;
}

.to-green-600{
  --gradient-to-color: #38a169 !important;
}

.to-green-700{
  --gradient-to-color: #2f855a !important;
}

.to-green-800{
  --gradient-to-color: #276749 !important;
}

.to-green-900{
  --gradient-to-color: #22543d !important;
}

.to-teal-100{
  --gradient-to-color: #e6fffa !important;
}

.to-teal-200{
  --gradient-to-color: #b2f5ea !important;
}

.to-teal-300{
  --gradient-to-color: #81e6d9 !important;
}

.to-teal-400{
  --gradient-to-color: #4fd1c5 !important;
}

.to-teal-500{
  --gradient-to-color: #38b2ac !important;
}

.to-teal-600{
  --gradient-to-color: #319795 !important;
}

.to-teal-700{
  --gradient-to-color: #2c7a7b !important;
}

.to-teal-800{
  --gradient-to-color: #285e61 !important;
}

.to-teal-900{
  --gradient-to-color: #234e52 !important;
}

.to-blue-100{
  --gradient-to-color: #ebf8ff !important;
}

.to-blue-200{
  --gradient-to-color: #bee3f8 !important;
}

.to-blue-300{
  --gradient-to-color: #90cdf4 !important;
}

.to-blue-400{
  --gradient-to-color: #63b3ed !important;
}

.to-blue-500{
  --gradient-to-color: #4299e1 !important;
}

.to-blue-600{
  --gradient-to-color: #3182ce !important;
}

.to-blue-700{
  --gradient-to-color: #2b6cb0 !important;
}

.to-blue-800{
  --gradient-to-color: #2c5282 !important;
}

.to-blue-900{
  --gradient-to-color: #2a4365 !important;
}

.to-indigo-100{
  --gradient-to-color: #ebf4ff !important;
}

.to-indigo-200{
  --gradient-to-color: #c3dafe !important;
}

.to-indigo-300{
  --gradient-to-color: #a3bffa !important;
}

.to-indigo-400{
  --gradient-to-color: #7f9cf5 !important;
}

.to-indigo-500{
  --gradient-to-color: #667eea !important;
}

.to-indigo-600{
  --gradient-to-color: #5a67d8 !important;
}

.to-indigo-700{
  --gradient-to-color: #4c51bf !important;
}

.to-indigo-800{
  --gradient-to-color: #434190 !important;
}

.to-indigo-900{
  --gradient-to-color: #3c366b !important;
}

.to-purple-100{
  --gradient-to-color: #faf5ff !important;
}

.to-purple-200{
  --gradient-to-color: #e9d8fd !important;
}

.to-purple-300{
  --gradient-to-color: #d6bcfa !important;
}

.to-purple-400{
  --gradient-to-color: #b794f4 !important;
}

.to-purple-500{
  --gradient-to-color: #9f7aea !important;
}

.to-purple-600{
  --gradient-to-color: #805ad5 !important;
}

.to-purple-700{
  --gradient-to-color: #6b46c1 !important;
}

.to-purple-800{
  --gradient-to-color: #553c9a !important;
}

.to-purple-900{
  --gradient-to-color: #44337a !important;
}

.to-pink-100{
  --gradient-to-color: #fff5f7 !important;
}

.to-pink-200{
  --gradient-to-color: #fed7e2 !important;
}

.to-pink-300{
  --gradient-to-color: #fbb6ce !important;
}

.to-pink-400{
  --gradient-to-color: #f687b3 !important;
}

.to-pink-500{
  --gradient-to-color: #ed64a6 !important;
}

.to-pink-600{
  --gradient-to-color: #d53f8c !important;
}

.to-pink-700{
  --gradient-to-color: #b83280 !important;
}

.to-pink-800{
  --gradient-to-color: #97266d !important;
}

.to-pink-900{
  --gradient-to-color: #702459 !important;
}

.to-clanGreen-100{
  --gradient-to-color: #45dd85 !important;
}

.to-clanGray-100{
  --gradient-to-color: #fafafa !important;
}

.to-clanGray-200{
  --gradient-to-color: #878396 !important;
}

.to-clanGray-300{
  --gradient-to-color: #e0e0e0 !important;
}

.to-clanYellow-100{
  --gradient-to-color: #fee661 !important;
}

.to-clanRed-100{
  --gradient-to-color: #ff6471 !important;
}

.to-clanYellowFaded{
  --gradient-to-color: #FBFA8D !important;
}

.bg-opacity-0{
  --bg-opacity: 0 !important;
}

.bg-opacity-25{
  --bg-opacity: 0.25 !important;
}

.bg-opacity-50{
  --bg-opacity: 0.5 !important;
}

.bg-opacity-75{
  --bg-opacity: 0.75 !important;
}

.bg-opacity-100{
  --bg-opacity: 1 !important;
}

.bg-bottom{
  background-position: bottom !important;
}

.bg-center{
  background-position: center !important;
}

.bg-left{
  background-position: left !important;
}

.bg-left-bottom{
  background-position: left bottom !important;
}

.bg-left-top{
  background-position: left top !important;
}

.bg-right{
  background-position: right !important;
}

.bg-right-bottom{
  background-position: right bottom !important;
}

.bg-right-top{
  background-position: right top !important;
}

.bg-top{
  background-position: top !important;
}

.bg-center-bottom{
  background-position: center bottom !important;
}

.bg-bottom-right{
  background-position: bottom right !important;
}

.bg-bottom-left{
  background-position: bottom left !important;
}

.bg-center-right{
  background-position: center right !important;
}

.bg-repeat{
  background-repeat: repeat !important;
}

.bg-no-repeat{
  background-repeat: no-repeat !important;
}

.bg-repeat-x{
  background-repeat: repeat-x !important;
}

.bg-repeat-y{
  background-repeat: repeat-y !important;
}

.bg-repeat-round{
  background-repeat: round !important;
}

.bg-repeat-space{
  background-repeat: space !important;
}

.bg-auto{
  background-size: auto !important;
}

.bg-cover{
  background-size: cover !important;
}

.bg-contain{
  background-size: contain !important;
}

.border-collapse{
  border-collapse: collapse !important;
}

.border-separate{
  border-collapse: separate !important;
}

.border-transparent{
  border-color: transparent !important;
}

.border-current{
  border-color: currentColor !important;
}

.border-black{
  --border-opacity: 1 !important;
  border-color: #000 !important;
  border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
}

.border-white{
  --border-opacity: 1 !important;
  border-color: #fff !important;
  border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
}

.border-gray-100{
  --border-opacity: 1 !important;
  border-color: #f7fafc !important;
  border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
}

.border-gray-200{
  --border-opacity: 1 !important;
  border-color: #edf2f7 !important;
  border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
}

.border-gray-300{
  --border-opacity: 1 !important;
  border-color: #e2e8f0 !important;
  border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
}

.border-gray-400{
  --border-opacity: 1 !important;
  border-color: #cbd5e0 !important;
  border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
}

.border-gray-500{
  --border-opacity: 1 !important;
  border-color: #a0aec0 !important;
  border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
}

.border-gray-600{
  --border-opacity: 1 !important;
  border-color: #718096 !important;
  border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
}

.border-gray-700{
  --border-opacity: 1 !important;
  border-color: #4a5568 !important;
  border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
}

.border-gray-800{
  --border-opacity: 1 !important;
  border-color: #2d3748 !important;
  border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
}

.border-gray-900{
  --border-opacity: 1 !important;
  border-color: #1a202c !important;
  border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
}

.border-red-100{
  --border-opacity: 1 !important;
  border-color: #fff5f5 !important;
  border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
}

.border-red-200{
  --border-opacity: 1 !important;
  border-color: #fed7d7 !important;
  border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
}

.border-red-300{
  --border-opacity: 1 !important;
  border-color: #feb2b2 !important;
  border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
}

.border-red-400{
  --border-opacity: 1 !important;
  border-color: #fc8181 !important;
  border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
}

.border-red-500{
  --border-opacity: 1 !important;
  border-color: #f56565 !important;
  border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
}

.border-red-600{
  --border-opacity: 1 !important;
  border-color: #e53e3e !important;
  border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
}

.border-red-700{
  --border-opacity: 1 !important;
  border-color: #c53030 !important;
  border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
}

.border-red-800{
  --border-opacity: 1 !important;
  border-color: #9b2c2c !important;
  border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
}

.border-red-900{
  --border-opacity: 1 !important;
  border-color: #742a2a !important;
  border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
}

.border-orange-100{
  --border-opacity: 1 !important;
  border-color: #fffaf0 !important;
  border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
}

.border-orange-200{
  --border-opacity: 1 !important;
  border-color: #feebc8 !important;
  border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
}

.border-orange-300{
  --border-opacity: 1 !important;
  border-color: #fbd38d !important;
  border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
}

.border-orange-400{
  --border-opacity: 1 !important;
  border-color: #f6ad55 !important;
  border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
}

.border-orange-500{
  --border-opacity: 1 !important;
  border-color: #ed8936 !important;
  border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
}

.border-orange-600{
  --border-opacity: 1 !important;
  border-color: #dd6b20 !important;
  border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
}

.border-orange-700{
  --border-opacity: 1 !important;
  border-color: #c05621 !important;
  border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
}

.border-orange-800{
  --border-opacity: 1 !important;
  border-color: #9c4221 !important;
  border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
}

.border-orange-900{
  --border-opacity: 1 !important;
  border-color: #7b341e !important;
  border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
}

.border-yellow-100{
  --border-opacity: 1 !important;
  border-color: #fffff0 !important;
  border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
}

.border-yellow-200{
  --border-opacity: 1 !important;
  border-color: #fefcbf !important;
  border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
}

.border-yellow-300{
  --border-opacity: 1 !important;
  border-color: #faf089 !important;
  border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
}

.border-yellow-400{
  --border-opacity: 1 !important;
  border-color: #f6e05e !important;
  border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
}

.border-yellow-500{
  --border-opacity: 1 !important;
  border-color: #ecc94b !important;
  border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
}

.border-yellow-600{
  --border-opacity: 1 !important;
  border-color: #d69e2e !important;
  border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
}

.border-yellow-700{
  --border-opacity: 1 !important;
  border-color: #b7791f !important;
  border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
}

.border-yellow-800{
  --border-opacity: 1 !important;
  border-color: #975a16 !important;
  border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
}

.border-yellow-900{
  --border-opacity: 1 !important;
  border-color: #744210 !important;
  border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
}

.border-green-100{
  --border-opacity: 1 !important;
  border-color: #f0fff4 !important;
  border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
}

.border-green-200{
  --border-opacity: 1 !important;
  border-color: #c6f6d5 !important;
  border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
}

.border-green-300{
  --border-opacity: 1 !important;
  border-color: #9ae6b4 !important;
  border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
}

.border-green-400{
  --border-opacity: 1 !important;
  border-color: #68d391 !important;
  border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
}

.border-green-500{
  --border-opacity: 1 !important;
  border-color: #48bb78 !important;
  border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
}

.border-green-600{
  --border-opacity: 1 !important;
  border-color: #38a169 !important;
  border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
}

.border-green-700{
  --border-opacity: 1 !important;
  border-color: #2f855a !important;
  border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
}

.border-green-800{
  --border-opacity: 1 !important;
  border-color: #276749 !important;
  border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
}

.border-green-900{
  --border-opacity: 1 !important;
  border-color: #22543d !important;
  border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
}

.border-teal-100{
  --border-opacity: 1 !important;
  border-color: #e6fffa !important;
  border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
}

.border-teal-200{
  --border-opacity: 1 !important;
  border-color: #b2f5ea !important;
  border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
}

.border-teal-300{
  --border-opacity: 1 !important;
  border-color: #81e6d9 !important;
  border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
}

.border-teal-400{
  --border-opacity: 1 !important;
  border-color: #4fd1c5 !important;
  border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
}

.border-teal-500{
  --border-opacity: 1 !important;
  border-color: #38b2ac !important;
  border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
}

.border-teal-600{
  --border-opacity: 1 !important;
  border-color: #319795 !important;
  border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
}

.border-teal-700{
  --border-opacity: 1 !important;
  border-color: #2c7a7b !important;
  border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
}

.border-teal-800{
  --border-opacity: 1 !important;
  border-color: #285e61 !important;
  border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
}

.border-teal-900{
  --border-opacity: 1 !important;
  border-color: #234e52 !important;
  border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
}

.border-blue-100{
  --border-opacity: 1 !important;
  border-color: #ebf8ff !important;
  border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
}

.border-blue-200{
  --border-opacity: 1 !important;
  border-color: #bee3f8 !important;
  border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
}

.border-blue-300{
  --border-opacity: 1 !important;
  border-color: #90cdf4 !important;
  border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
}

.border-blue-400{
  --border-opacity: 1 !important;
  border-color: #63b3ed !important;
  border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
}

.border-blue-500{
  --border-opacity: 1 !important;
  border-color: #4299e1 !important;
  border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
}

.border-blue-600{
  --border-opacity: 1 !important;
  border-color: #3182ce !important;
  border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
}

.border-blue-700{
  --border-opacity: 1 !important;
  border-color: #2b6cb0 !important;
  border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
}

.border-blue-800{
  --border-opacity: 1 !important;
  border-color: #2c5282 !important;
  border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
}

.border-blue-900{
  --border-opacity: 1 !important;
  border-color: #2a4365 !important;
  border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
}

.border-indigo-100{
  --border-opacity: 1 !important;
  border-color: #ebf4ff !important;
  border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
}

.border-indigo-200{
  --border-opacity: 1 !important;
  border-color: #c3dafe !important;
  border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
}

.border-indigo-300{
  --border-opacity: 1 !important;
  border-color: #a3bffa !important;
  border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
}

.border-indigo-400{
  --border-opacity: 1 !important;
  border-color: #7f9cf5 !important;
  border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
}

.border-indigo-500{
  --border-opacity: 1 !important;
  border-color: #667eea !important;
  border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
}

.border-indigo-600{
  --border-opacity: 1 !important;
  border-color: #5a67d8 !important;
  border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
}

.border-indigo-700{
  --border-opacity: 1 !important;
  border-color: #4c51bf !important;
  border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
}

.border-indigo-800{
  --border-opacity: 1 !important;
  border-color: #434190 !important;
  border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
}

.border-indigo-900{
  --border-opacity: 1 !important;
  border-color: #3c366b !important;
  border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
}

.border-purple-100{
  --border-opacity: 1 !important;
  border-color: #faf5ff !important;
  border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
}

.border-purple-200{
  --border-opacity: 1 !important;
  border-color: #e9d8fd !important;
  border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
}

.border-purple-300{
  --border-opacity: 1 !important;
  border-color: #d6bcfa !important;
  border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
}

.border-purple-400{
  --border-opacity: 1 !important;
  border-color: #b794f4 !important;
  border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
}

.border-purple-500{
  --border-opacity: 1 !important;
  border-color: #9f7aea !important;
  border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
}

.border-purple-600{
  --border-opacity: 1 !important;
  border-color: #805ad5 !important;
  border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
}

.border-purple-700{
  --border-opacity: 1 !important;
  border-color: #6b46c1 !important;
  border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
}

.border-purple-800{
  --border-opacity: 1 !important;
  border-color: #553c9a !important;
  border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
}

.border-purple-900{
  --border-opacity: 1 !important;
  border-color: #44337a !important;
  border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
}

.border-pink-100{
  --border-opacity: 1 !important;
  border-color: #fff5f7 !important;
  border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
}

.border-pink-200{
  --border-opacity: 1 !important;
  border-color: #fed7e2 !important;
  border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
}

.border-pink-300{
  --border-opacity: 1 !important;
  border-color: #fbb6ce !important;
  border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
}

.border-pink-400{
  --border-opacity: 1 !important;
  border-color: #f687b3 !important;
  border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
}

.border-pink-500{
  --border-opacity: 1 !important;
  border-color: #ed64a6 !important;
  border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
}

.border-pink-600{
  --border-opacity: 1 !important;
  border-color: #d53f8c !important;
  border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
}

.border-pink-700{
  --border-opacity: 1 !important;
  border-color: #b83280 !important;
  border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
}

.border-pink-800{
  --border-opacity: 1 !important;
  border-color: #97266d !important;
  border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
}

.border-pink-900{
  --border-opacity: 1 !important;
  border-color: #702459 !important;
  border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
}

.border-clanGreen-100{
  --border-opacity: 1 !important;
  border-color: #45dd85 !important;
  border-color: rgba(69, 221, 133, var(--border-opacity)) !important;
}

.border-clanGray-100{
  --border-opacity: 1 !important;
  border-color: #fafafa !important;
  border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
}

.border-clanGray-200{
  --border-opacity: 1 !important;
  border-color: #878396 !important;
  border-color: rgba(135, 131, 150, var(--border-opacity)) !important;
}

.border-clanGray-300{
  --border-opacity: 1 !important;
  border-color: #e0e0e0 !important;
  border-color: rgba(224, 224, 224, var(--border-opacity)) !important;
}

.border-clanYellow-100{
  --border-opacity: 1 !important;
  border-color: #fee661 !important;
  border-color: rgba(254, 230, 97, var(--border-opacity)) !important;
}

.border-clanRed-100{
  --border-opacity: 1 !important;
  border-color: #ff6471 !important;
  border-color: rgba(255, 100, 113, var(--border-opacity)) !important;
}

.border-opacity-0{
  --border-opacity: 0 !important;
}

.border-opacity-25{
  --border-opacity: 0.25 !important;
}

.border-opacity-50{
  --border-opacity: 0.5 !important;
}

.border-opacity-75{
  --border-opacity: 0.75 !important;
}

.border-opacity-100{
  --border-opacity: 1 !important;
}

.rounded-none{
  border-radius: 0 !important;
}

.rounded-sm{
  border-radius: 0.125rem !important;
}

.rounded{
  border-radius: 0.25rem !important;
}

.rounded-md{
  border-radius: 0.375rem !important;
}

.rounded-lg{
  border-radius: 0.5rem !important;
}

.rounded-xl{
  border-radius: 0.75rem !important;
}

.rounded-2xl{
  border-radius: 1rem !important;
}

.rounded-3xl{
  border-radius: 1.5rem !important;
}

.rounded-full{
  border-radius: 9999px !important;
}

.rounded-clanCard{
  border-radius: 0.9375rem !important;
}

.rounded-round{
  border-radius: 50% !important;
}

.rounded-clanShareItem{
  border-radius: 2rem !important;
}

.rounded-clanListItem{
  border-radius: 1.78rem !important;
}

.rounded-t-none{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-r-none{
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-b-none{
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-l-none{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-t-sm{
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.rounded-r-sm{
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-b-sm{
  border-bottom-right-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-l-sm{
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-t{
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-r{
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-b{
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-l{
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-t-md{
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-r-md{
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-b-md{
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-l-md{
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-t-lg{
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-r-lg{
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-b-lg{
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-l-lg{
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-t-xl{
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.rounded-r-xl{
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-b-xl{
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-l-xl{
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-t-2xl{
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.rounded-r-2xl{
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.rounded-b-2xl{
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rounded-l-2xl{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rounded-t-3xl{
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.rounded-r-3xl{
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.rounded-b-3xl{
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-l-3xl{
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-t-full{
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}

.rounded-r-full{
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.rounded-b-full{
  border-bottom-right-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.rounded-l-full{
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.rounded-t-clanCard{
  border-top-left-radius: 0.9375rem !important;
  border-top-right-radius: 0.9375rem !important;
}

.rounded-r-clanCard{
  border-top-right-radius: 0.9375rem !important;
  border-bottom-right-radius: 0.9375rem !important;
}

.rounded-b-clanCard{
  border-bottom-right-radius: 0.9375rem !important;
  border-bottom-left-radius: 0.9375rem !important;
}

.rounded-l-clanCard{
  border-top-left-radius: 0.9375rem !important;
  border-bottom-left-radius: 0.9375rem !important;
}

.rounded-t-round{
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-r-round{
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-b-round{
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-l-round{
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-t-clanShareItem{
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important;
}

.rounded-r-clanShareItem{
  border-top-right-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
}

.rounded-b-clanShareItem{
  border-bottom-right-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important;
}

.rounded-l-clanShareItem{
  border-top-left-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important;
}

.rounded-t-clanListItem{
  border-top-left-radius: 1.78rem !important;
  border-top-right-radius: 1.78rem !important;
}

.rounded-r-clanListItem{
  border-top-right-radius: 1.78rem !important;
  border-bottom-right-radius: 1.78rem !important;
}

.rounded-b-clanListItem{
  border-bottom-right-radius: 1.78rem !important;
  border-bottom-left-radius: 1.78rem !important;
}

.rounded-l-clanListItem{
  border-top-left-radius: 1.78rem !important;
  border-bottom-left-radius: 1.78rem !important;
}

.rounded-tl-none{
  border-top-left-radius: 0 !important;
}

.rounded-tr-none{
  border-top-right-radius: 0 !important;
}

.rounded-br-none{
  border-bottom-right-radius: 0 !important;
}

.rounded-bl-none{
  border-bottom-left-radius: 0 !important;
}

.rounded-tl-sm{
  border-top-left-radius: 0.125rem !important;
}

.rounded-tr-sm{
  border-top-right-radius: 0.125rem !important;
}

.rounded-br-sm{
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-bl-sm{
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-tl{
  border-top-left-radius: 0.25rem !important;
}

.rounded-tr{
  border-top-right-radius: 0.25rem !important;
}

.rounded-br{
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bl{
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-tl-md{
  border-top-left-radius: 0.375rem !important;
}

.rounded-tr-md{
  border-top-right-radius: 0.375rem !important;
}

.rounded-br-md{
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bl-md{
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-tl-lg{
  border-top-left-radius: 0.5rem !important;
}

.rounded-tr-lg{
  border-top-right-radius: 0.5rem !important;
}

.rounded-br-lg{
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-bl-lg{
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-tl-xl{
  border-top-left-radius: 0.75rem !important;
}

.rounded-tr-xl{
  border-top-right-radius: 0.75rem !important;
}

.rounded-br-xl{
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-bl-xl{
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-tl-2xl{
  border-top-left-radius: 1rem !important;
}

.rounded-tr-2xl{
  border-top-right-radius: 1rem !important;
}

.rounded-br-2xl{
  border-bottom-right-radius: 1rem !important;
}

.rounded-bl-2xl{
  border-bottom-left-radius: 1rem !important;
}

.rounded-tl-3xl{
  border-top-left-radius: 1.5rem !important;
}

.rounded-tr-3xl{
  border-top-right-radius: 1.5rem !important;
}

.rounded-br-3xl{
  border-bottom-right-radius: 1.5rem !important;
}

.rounded-bl-3xl{
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-tl-full{
  border-top-left-radius: 9999px !important;
}

.rounded-tr-full{
  border-top-right-radius: 9999px !important;
}

.rounded-br-full{
  border-bottom-right-radius: 9999px !important;
}

.rounded-bl-full{
  border-bottom-left-radius: 9999px !important;
}

.rounded-tl-clanCard{
  border-top-left-radius: 0.9375rem !important;
}

.rounded-tr-clanCard{
  border-top-right-radius: 0.9375rem !important;
}

.rounded-br-clanCard{
  border-bottom-right-radius: 0.9375rem !important;
}

.rounded-bl-clanCard{
  border-bottom-left-radius: 0.9375rem !important;
}

.rounded-tl-round{
  border-top-left-radius: 50% !important;
}

.rounded-tr-round{
  border-top-right-radius: 50% !important;
}

.rounded-br-round{
  border-bottom-right-radius: 50% !important;
}

.rounded-bl-round{
  border-bottom-left-radius: 50% !important;
}

.rounded-tl-clanShareItem{
  border-top-left-radius: 2rem !important;
}

.rounded-tr-clanShareItem{
  border-top-right-radius: 2rem !important;
}

.rounded-br-clanShareItem{
  border-bottom-right-radius: 2rem !important;
}

.rounded-bl-clanShareItem{
  border-bottom-left-radius: 2rem !important;
}

.rounded-tl-clanListItem{
  border-top-left-radius: 1.78rem !important;
}

.rounded-tr-clanListItem{
  border-top-right-radius: 1.78rem !important;
}

.rounded-br-clanListItem{
  border-bottom-right-radius: 1.78rem !important;
}

.rounded-bl-clanListItem{
  border-bottom-left-radius: 1.78rem !important;
}

.border-solid{
  border-style: solid !important;
}

.border-dashed{
  border-style: dashed !important;
}

.border-dotted{
  border-style: dotted !important;
}

.border-double{
  border-style: double !important;
}

.border-none{
  border-style: none !important;
}

.border-0{
  border-width: 0 !important;
}

.border-2{
  border-width: 2px !important;
}

.border-4{
  border-width: 4px !important;
}

.border-8{
  border-width: 8px !important;
}

.border{
  border-width: 1px !important;
}

.border-t-0{
  border-top-width: 0 !important;
}

.border-r-0{
  border-right-width: 0 !important;
}

.border-b-0{
  border-bottom-width: 0 !important;
}

.border-l-0{
  border-left-width: 0 !important;
}

.border-t-2{
  border-top-width: 2px !important;
}

.border-r-2{
  border-right-width: 2px !important;
}

.border-b-2{
  border-bottom-width: 2px !important;
}

.border-l-2{
  border-left-width: 2px !important;
}

.border-t-4{
  border-top-width: 4px !important;
}

.border-r-4{
  border-right-width: 4px !important;
}

.border-b-4{
  border-bottom-width: 4px !important;
}

.border-l-4{
  border-left-width: 4px !important;
}

.border-t-8{
  border-top-width: 8px !important;
}

.border-r-8{
  border-right-width: 8px !important;
}

.border-b-8{
  border-bottom-width: 8px !important;
}

.border-l-8{
  border-left-width: 8px !important;
}

.border-t{
  border-top-width: 1px !important;
}

.border-r{
  border-right-width: 1px !important;
}

.border-b{
  border-bottom-width: 1px !important;
}

.border-l{
  border-left-width: 1px !important;
}

.box-border{
  box-sizing: border-box !important;
}

.box-content{
  box-sizing: content-box !important;
}

.cursor-auto{
  cursor: auto !important;
}

.cursor-default{
  cursor: default !important;
}

.cursor-pointer{
  cursor: pointer !important;
}

.cursor-wait{
  cursor: wait !important;
}

.cursor-text{
  cursor: text !important;
}

.cursor-move{
  cursor: move !important;
}

.cursor-not-allowed{
  cursor: not-allowed !important;
}

.block{
  display: block !important;
}

.inline-block{
  display: inline-block !important;
}

.inline{
  display: inline !important;
}

.flex{
  display: flex !important;
}

.inline-flex{
  display: inline-flex !important;
}

.table{
  display: table !important;
}

.table-caption{
  display: table-caption !important;
}

.table-cell{
  display: table-cell !important;
}

.table-column{
  display: table-column !important;
}

.table-column-group{
  display: table-column-group !important;
}

.table-footer-group{
  display: table-footer-group !important;
}

.table-header-group{
  display: table-header-group !important;
}

.table-row-group{
  display: table-row-group !important;
}

.table-row{
  display: table-row !important;
}

.flow-root{
  display: flow-root !important;
}

.grid{
  display: grid !important;
}

.inline-grid{
  display: inline-grid !important;
}

.contents{
  display: contents !important;
}

.hidden{
  display: none !important;
}

.flex-row{
  flex-direction: row !important;
}

.flex-row-reverse{
  flex-direction: row-reverse !important;
}

.flex-col{
  flex-direction: column !important;
}

.flex-col-reverse{
  flex-direction: column-reverse !important;
}

.flex-wrap{
  flex-wrap: wrap !important;
}

.flex-wrap-reverse{
  flex-wrap: wrap-reverse !important;
}

.flex-no-wrap{
  flex-wrap: nowrap !important;
}

.place-items-auto{
  place-items: auto !important;
}

.place-items-start{
  place-items: start !important;
}

.place-items-end{
  place-items: end !important;
}

.place-items-center{
  place-items: center !important;
}

.place-items-stretch{
  place-items: stretch !important;
}

.place-content-center{
  place-content: center !important;
}

.place-content-start{
  place-content: start !important;
}

.place-content-end{
  place-content: end !important;
}

.place-content-between{
  place-content: space-between !important;
}

.place-content-around{
  place-content: space-around !important;
}

.place-content-evenly{
  place-content: space-evenly !important;
}

.place-content-stretch{
  place-content: stretch !important;
}

.place-self-auto{
  place-self: auto !important;
}

.place-self-start{
  place-self: start !important;
}

.place-self-end{
  place-self: end !important;
}

.place-self-center{
  place-self: center !important;
}

.place-self-stretch{
  place-self: stretch !important;
}

.items-start{
  align-items: flex-start !important;
}

.items-end{
  align-items: flex-end !important;
}

.items-center{
  align-items: center !important;
}

.items-baseline{
  align-items: baseline !important;
}

.items-stretch{
  align-items: stretch !important;
}

.content-center{
  align-content: center !important;
}

.content-start{
  align-content: flex-start !important;
}

.content-end{
  align-content: flex-end !important;
}

.content-between{
  align-content: space-between !important;
}

.content-around{
  align-content: space-around !important;
}

.content-evenly{
  align-content: space-evenly !important;
}

.self-auto{
  align-self: auto !important;
}

.self-start{
  align-self: flex-start !important;
}

.self-end{
  align-self: flex-end !important;
}

.self-center{
  align-self: center !important;
}

.self-stretch{
  align-self: stretch !important;
}

.justify-items-auto{
  justify-items: auto !important;
}

.justify-items-start{
  justify-items: start !important;
}

.justify-items-end{
  justify-items: end !important;
}

.justify-items-center{
  justify-items: center !important;
}

.justify-items-stretch{
  justify-items: stretch !important;
}

.justify-start{
  justify-content: flex-start !important;
}

.justify-end{
  justify-content: flex-end !important;
}

.justify-center{
  justify-content: center !important;
}

.justify-between{
  justify-content: space-between !important;
}

.justify-around{
  justify-content: space-around !important;
}

.justify-evenly{
  justify-content: space-evenly !important;
}

.justify-self-auto{
  justify-self: auto !important;
}

.justify-self-start{
  justify-self: start !important;
}

.justify-self-end{
  justify-self: end !important;
}

.justify-self-center{
  justify-self: center !important;
}

.justify-self-stretch{
  justify-self: stretch !important;
}

.flex-0{
  flex: 1 0 0 !important;
}

.flex-1{
  flex: 1 1 0% !important;
}

.flex-auto{
  flex: 1 1 auto !important;
}

.flex-initial{
  flex: 0 1 auto !important;
}

.flex-none{
  flex: none !important;
}

.flex-grow-0{
  flex-grow: 0 !important;
}

.flex-grow{
  flex-grow: 1 !important;
}

.flex-shrink-0{
  flex-shrink: 0 !important;
}

.flex-shrink{
  flex-shrink: 1 !important;
}

.order-1{
  order: 1 !important;
}

.order-2{
  order: 2 !important;
}

.order-3{
  order: 3 !important;
}

.order-4{
  order: 4 !important;
}

.order-5{
  order: 5 !important;
}

.order-6{
  order: 6 !important;
}

.order-7{
  order: 7 !important;
}

.order-8{
  order: 8 !important;
}

.order-9{
  order: 9 !important;
}

.order-10{
  order: 10 !important;
}

.order-11{
  order: 11 !important;
}

.order-12{
  order: 12 !important;
}

.order-first{
  order: -9999 !important;
}

.order-last{
  order: 9999 !important;
}

.order-none{
  order: 0 !important;
}

.float-right{
  float: right !important;
}

.float-left{
  float: left !important;
}

.float-none{
  float: none !important;
}

.clear-left{
  clear: left !important;
}

.clear-right{
  clear: right !important;
}

.clear-both{
  clear: both !important;
}

.clear-none{
  clear: none !important;
}

.font-gilroy{
  font-family: Gilroy !important;
}

.font-notoSans{
  font-family: NotoSans !important;
}

.font-notoSansRegular{
  font-family: NotoSansRegular !important;
}

.font-hairline{
  font-weight: 100 !important;
}

.font-thin{
  font-weight: 200 !important;
}

.font-light{
  font-weight: 300 !important;
}

.font-normal{
  font-weight: 400 !important;
}

.font-medium{
  font-weight: 500 !important;
}

.font-semibold{
  font-weight: 600 !important;
}

.font-bold{
  font-weight: 700 !important;
}

.font-extrabold{
  font-weight: 800 !important;
}

.font-black{
  font-weight: 900 !important;
}

.h-0{
  height: 0 !important;
}

.h-1{
  height: 0.25rem !important;
}

.h-2{
  height: 0.5rem !important;
}

.h-3{
  height: 0.75rem !important;
}

.h-4{
  height: 1rem !important;
}

.h-5{
  height: 1.25rem !important;
}

.h-6{
  height: 1.5rem !important;
}

.h-8{
  height: 2rem !important;
}

.h-10{
  height: 2.5rem !important;
}

.h-12{
  height: 3rem !important;
}

.h-16{
  height: 4rem !important;
}

.h-20{
  height: 5rem !important;
}

.h-24{
  height: 6rem !important;
}

.h-32{
  height: 8rem !important;
}

.h-36{
  height: 9rem !important;
}

.h-40{
  height: 10rem !important;
}

.h-48{
  height: 12rem !important;
}

.h-56{
  height: 14rem !important;
}

.h-64{
  height: 16rem !important;
}

.h-auto{
  height: auto !important;
}

.h-px{
  height: 1px !important;
}

.h-clanScreenEdge{
  height: 1.428rem !important;
}

.h-clanFlowStepScreenEdge{
  height: 1.563rem !important;
}

.h-clanCard{
  height: 90% !important;
}

.h-clanBtn{
  height: 2.5rem !important;
}

.h-clanBtnFat{
  height: 3.5rem !important;
}

.h-clanAvatar{
  height: 12.5rem !important;
}

.h-clanProfileAvatar{
  height: 12.5rem !important;
}

.h-full{
  height: 100% !important;
}

.h-screen{
  height: 100vh !important;
}

.h-clanCardCircle{
  height: 12rem !important;
}

.h-clanProfileAvatarSmall{
  height: 2.15rem !important;
}

.h-clan30rem{
  height: 30rem !important;
}

.h-clanFixedBottom{
  height: 3.575rem !important;
}

.h-clanCardShort{
  height: 50vh !important;
}

.h-clanCardLong{
  height: 70vh !important;
}

.h-clanCardFull{
  height: 80vh !important;
}

.text-xs{
  font-size: 0.75rem !important;
}

.text-sm{
  font-size: 0.875rem !important;
}

.text-base{
  font-size: 1rem !important;
}

.text-lg{
  font-size: 1.125rem !important;
}

.text-xl{
  font-size: 1.25rem !important;
}

.text-2xl{
  font-size: 1.5rem !important;
}

.text-3xl{
  font-size: 1.875rem !important;
}

.text-4xl{
  font-size: 2.25rem !important;
}

.text-5xl{
  font-size: 3rem !important;
}

.text-6xl{
  font-size: 4rem !important;
}

.text-clanH5{
  font-size: 0.8571rem !important;
  line-height: 1rem !important;
}

.text-clanH4{
  font-size: 1rem !important;
  line-height: 1.286rem !important;
}

.text-clanH3{
  font-size: 1.286rem !important;
  line-height: 1.571rem !important;
}

.text-clanH2{
  font-size: 1.714rem !important;
  line-height: 2rem !important;
}

.text-clanH1{
  font-size: 3.214rem !important;
  line-height: 3.571rem !important;
}

.text-clanP{
  font-size: 1rem !important;
  line-height: 1.286rem !important;
}

.text-clan-landing{
  font-size: 13vw !important;
  line-height: 7vh !important;
}

.leading-3{
  line-height: .75rem !important;
}

.leading-4{
  line-height: 1rem !important;
}

.leading-5{
  line-height: 1.25rem !important;
}

.leading-6{
  line-height: 1.5rem !important;
}

.leading-7{
  line-height: 1.75rem !important;
}

.leading-8{
  line-height: 2rem !important;
}

.leading-9{
  line-height: 2.25rem !important;
}

.leading-10{
  line-height: 2.5rem !important;
}

.leading-none{
  line-height: 1 !important;
}

.leading-tight{
  line-height: 1.25 !important;
}

.leading-snug{
  line-height: 1.375 !important;
}

.leading-normal{
  line-height: 1.5 !important;
}

.leading-relaxed{
  line-height: 1.625 !important;
}

.leading-loose{
  line-height: 2 !important;
}

.leading-clan-landing{
  line-height: 2.9rem !important;
}

.leading-clanH7{
  line-height: 1.571rem !important;
}

.list-inside{
  list-style-position: inside !important;
}

.list-outside{
  list-style-position: outside !important;
}

.list-none{
  list-style-type: none !important;
}

.list-disc{
  list-style-type: disc !important;
}

.list-decimal{
  list-style-type: decimal !important;
}

.m-0{
  margin: 0 !important;
}

.m-1{
  margin: 0.25rem !important;
}

.m-2{
  margin: 0.5rem !important;
}

.m-3{
  margin: 0.75rem !important;
}

.m-4{
  margin: 1rem !important;
}

.m-5{
  margin: 1.25rem !important;
}

.m-6{
  margin: 1.5rem !important;
}

.m-8{
  margin: 2rem !important;
}

.m-10{
  margin: 2.5rem !important;
}

.m-12{
  margin: 3rem !important;
}

.m-16{
  margin: 4rem !important;
}

.m-20{
  margin: 5rem !important;
}

.m-24{
  margin: 6rem !important;
}

.m-32{
  margin: 8rem !important;
}

.m-36{
  margin: 9rem !important;
}

.m-40{
  margin: 10rem !important;
}

.m-48{
  margin: 12rem !important;
}

.m-56{
  margin: 14rem !important;
}

.m-64{
  margin: 16rem !important;
}

.m-auto{
  margin: auto !important;
}

.m-px{
  margin: 1px !important;
}

.m-clanScreenEdge{
  margin: 1.428rem !important;
}

.m-clanFlowStepScreenEdge{
  margin: 1.563rem !important;
}

.m-clanCard{
  margin: 32rem !important;
}

.m-clanBtn{
  margin: 2.5rem !important;
}

.m-clanBtnFat{
  margin: 3.5rem !important;
}

.m-clanAvatar{
  margin: 12.5rem !important;
}

.m-clanProfileAvatar{
  margin: 8rem !important;
}

.-m-1{
  margin: -0.25rem !important;
}

.-m-2{
  margin: -0.5rem !important;
}

.-m-3{
  margin: -0.75rem !important;
}

.-m-4{
  margin: -1rem !important;
}

.-m-5{
  margin: -1.25rem !important;
}

.-m-6{
  margin: -1.5rem !important;
}

.-m-8{
  margin: -2rem !important;
}

.-m-10{
  margin: -2.5rem !important;
}

.-m-12{
  margin: -3rem !important;
}

.-m-16{
  margin: -4rem !important;
}

.-m-20{
  margin: -5rem !important;
}

.-m-24{
  margin: -6rem !important;
}

.-m-32{
  margin: -8rem !important;
}

.-m-36{
  margin: -9rem !important;
}

.-m-40{
  margin: -10rem !important;
}

.-m-48{
  margin: -12rem !important;
}

.-m-56{
  margin: -14rem !important;
}

.-m-64{
  margin: -16rem !important;
}

.-m-px{
  margin: -1px !important;
}

.-m-clanScreenEdge{
  margin: -1.428rem !important;
}

.-m-clanFlowStepScreenEdge{
  margin: -1.563rem !important;
}

.-m-clanCard{
  margin: -32rem !important;
}

.-m-clanBtn{
  margin: -2.5rem !important;
}

.-m-clanBtnFat{
  margin: -3.5rem !important;
}

.-m-clanAvatar{
  margin: -12.5rem !important;
}

.-m-clanProfileAvatar{
  margin: -8rem !important;
}

.m-clanFlowHeaderTop{
  margin: 10vh !important;
}

.m-clanFlowContentTop{
  margin: 10vh !important;
}

.m-clanRelativeFillScreen{
  margin: 0 -50vw !important;
}

.m-clan25percent{
  margin: 25% !important;
}

.m-clan30percent{
  margin: 30% !important;
}

.m-clan50percent{
  margin: 50% !important;
}

.my-0{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-0{
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-1{
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mx-1{
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.my-2{
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mx-2{
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.my-3{
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.mx-3{
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.my-4{
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-4{
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-5{
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.mx-5{
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.my-6{
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mx-6{
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-8{
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mx-8{
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.my-10{
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.mx-10{
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.my-12{
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mx-12{
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-16{
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mx-16{
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.my-20{
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.mx-20{
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.my-24{
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.mx-24{
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.my-32{
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.mx-32{
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}

.my-36{
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.mx-36{
  margin-left: 9rem !important;
  margin-right: 9rem !important;
}

.my-40{
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.mx-40{
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.my-48{
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.mx-48{
  margin-left: 12rem !important;
  margin-right: 12rem !important;
}

.my-56{
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.mx-56{
  margin-left: 14rem !important;
  margin-right: 14rem !important;
}

.my-64{
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.mx-64{
  margin-left: 16rem !important;
  margin-right: 16rem !important;
}

.my-auto{
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mx-auto{
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-px{
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.mx-px{
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.my-clanScreenEdge{
  margin-top: 1.428rem !important;
  margin-bottom: 1.428rem !important;
}

.mx-clanScreenEdge{
  margin-left: 1.428rem !important;
  margin-right: 1.428rem !important;
}

.my-clanFlowStepScreenEdge{
  margin-top: 1.563rem !important;
  margin-bottom: 1.563rem !important;
}

.mx-clanFlowStepScreenEdge{
  margin-left: 1.563rem !important;
  margin-right: 1.563rem !important;
}

.my-clanCard{
  margin-top: 32rem !important;
  margin-bottom: 32rem !important;
}

.mx-clanCard{
  margin-left: 32rem !important;
  margin-right: 32rem !important;
}

.my-clanBtn{
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.mx-clanBtn{
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.my-clanBtnFat{
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.mx-clanBtnFat{
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.my-clanAvatar{
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important;
}

.mx-clanAvatar{
  margin-left: 12.5rem !important;
  margin-right: 12.5rem !important;
}

.my-clanProfileAvatar{
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.mx-clanProfileAvatar{
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}

.-my-1{
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.-mx-1{
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-my-2{
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.-mx-2{
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-my-3{
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.-mx-3{
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.-my-4{
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-mx-4{
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-my-5{
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.-mx-5{
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.-my-6{
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-mx-6{
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-my-8{
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-mx-8{
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-my-10{
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.-mx-10{
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.-my-12{
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-mx-12{
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-my-16{
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-mx-16{
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-my-20{
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.-mx-20{
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.-my-24{
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.-mx-24{
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.-my-32{
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.-mx-32{
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.-my-36{
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.-mx-36{
  margin-left: -9rem !important;
  margin-right: -9rem !important;
}

.-my-40{
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.-mx-40{
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}

.-my-48{
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.-mx-48{
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}

.-my-56{
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.-mx-56{
  margin-left: -14rem !important;
  margin-right: -14rem !important;
}

.-my-64{
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.-mx-64{
  margin-left: -16rem !important;
  margin-right: -16rem !important;
}

.-my-px{
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.-mx-px{
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.-my-clanScreenEdge{
  margin-top: -1.428rem !important;
  margin-bottom: -1.428rem !important;
}

.-mx-clanScreenEdge{
  margin-left: -1.428rem !important;
  margin-right: -1.428rem !important;
}

.-my-clanFlowStepScreenEdge{
  margin-top: -1.563rem !important;
  margin-bottom: -1.563rem !important;
}

.-mx-clanFlowStepScreenEdge{
  margin-left: -1.563rem !important;
  margin-right: -1.563rem !important;
}

.-my-clanCard{
  margin-top: -32rem !important;
  margin-bottom: -32rem !important;
}

.-mx-clanCard{
  margin-left: -32rem !important;
  margin-right: -32rem !important;
}

.-my-clanBtn{
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.-mx-clanBtn{
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.-my-clanBtnFat{
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.-mx-clanBtnFat{
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.-my-clanAvatar{
  margin-top: -12.5rem !important;
  margin-bottom: -12.5rem !important;
}

.-mx-clanAvatar{
  margin-left: -12.5rem !important;
  margin-right: -12.5rem !important;
}

.-my-clanProfileAvatar{
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.-mx-clanProfileAvatar{
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.my-clanFlowHeaderTop{
  margin-top: 10vh !important;
  margin-bottom: 10vh !important;
}

.mx-clanFlowHeaderTop{
  margin-left: 10vh !important;
  margin-right: 10vh !important;
}

.my-clanFlowContentTop{
  margin-top: 10vh !important;
  margin-bottom: 10vh !important;
}

.mx-clanFlowContentTop{
  margin-left: 10vh !important;
  margin-right: 10vh !important;
}

.my-clanRelativeFillScreen{
  margin-top: 0 -50vw !important;
  margin-bottom: 0 -50vw !important;
}

.mx-clanRelativeFillScreen{
  margin-left: 0 -50vw !important;
  margin-right: 0 -50vw !important;
}

.my-clan25percent{
  margin-top: 25% !important;
  margin-bottom: 25% !important;
}

.mx-clan25percent{
  margin-left: 25% !important;
  margin-right: 25% !important;
}

.my-clan30percent{
  margin-top: 30% !important;
  margin-bottom: 30% !important;
}

.mx-clan30percent{
  margin-left: 30% !important;
  margin-right: 30% !important;
}

.my-clan50percent{
  margin-top: 50% !important;
  margin-bottom: 50% !important;
}

.mx-clan50percent{
  margin-left: 50% !important;
  margin-right: 50% !important;
}

.mt-0{
  margin-top: 0 !important;
}

.mr-0{
  margin-right: 0 !important;
}

.mb-0{
  margin-bottom: 0 !important;
}

.ml-0{
  margin-left: 0 !important;
}

.mt-1{
  margin-top: 0.25rem !important;
}

.mr-1{
  margin-right: 0.25rem !important;
}

.mb-1{
  margin-bottom: 0.25rem !important;
}

.ml-1{
  margin-left: 0.25rem !important;
}

.mt-2{
  margin-top: 0.5rem !important;
}

.mr-2{
  margin-right: 0.5rem !important;
}

.mb-2{
  margin-bottom: 0.5rem !important;
}

.ml-2{
  margin-left: 0.5rem !important;
}

.mt-3{
  margin-top: 0.75rem !important;
}

.mr-3{
  margin-right: 0.75rem !important;
}

.mb-3{
  margin-bottom: 0.75rem !important;
}

.ml-3{
  margin-left: 0.75rem !important;
}

.mt-4{
  margin-top: 1rem !important;
}

.mr-4{
  margin-right: 1rem !important;
}

.mb-4{
  margin-bottom: 1rem !important;
}

.ml-4{
  margin-left: 1rem !important;
}

.mt-5{
  margin-top: 1.25rem !important;
}

.mr-5{
  margin-right: 1.25rem !important;
}

.mb-5{
  margin-bottom: 1.25rem !important;
}

.ml-5{
  margin-left: 1.25rem !important;
}

.mt-6{
  margin-top: 1.5rem !important;
}

.mr-6{
  margin-right: 1.5rem !important;
}

.mb-6{
  margin-bottom: 1.5rem !important;
}

.ml-6{
  margin-left: 1.5rem !important;
}

.mt-8{
  margin-top: 2rem !important;
}

.mr-8{
  margin-right: 2rem !important;
}

.mb-8{
  margin-bottom: 2rem !important;
}

.ml-8{
  margin-left: 2rem !important;
}

.mt-10{
  margin-top: 2.5rem !important;
}

.mr-10{
  margin-right: 2.5rem !important;
}

.mb-10{
  margin-bottom: 2.5rem !important;
}

.ml-10{
  margin-left: 2.5rem !important;
}

.mt-12{
  margin-top: 3rem !important;
}

.mr-12{
  margin-right: 3rem !important;
}

.mb-12{
  margin-bottom: 3rem !important;
}

.ml-12{
  margin-left: 3rem !important;
}

.mt-16{
  margin-top: 4rem !important;
}

.mr-16{
  margin-right: 4rem !important;
}

.mb-16{
  margin-bottom: 4rem !important;
}

.ml-16{
  margin-left: 4rem !important;
}

.mt-20{
  margin-top: 5rem !important;
}

.mr-20{
  margin-right: 5rem !important;
}

.mb-20{
  margin-bottom: 5rem !important;
}

.ml-20{
  margin-left: 5rem !important;
}

.mt-24{
  margin-top: 6rem !important;
}

.mr-24{
  margin-right: 6rem !important;
}

.mb-24{
  margin-bottom: 6rem !important;
}

.ml-24{
  margin-left: 6rem !important;
}

.mt-32{
  margin-top: 8rem !important;
}

.mr-32{
  margin-right: 8rem !important;
}

.mb-32{
  margin-bottom: 8rem !important;
}

.ml-32{
  margin-left: 8rem !important;
}

.mt-36{
  margin-top: 9rem !important;
}

.mr-36{
  margin-right: 9rem !important;
}

.mb-36{
  margin-bottom: 9rem !important;
}

.ml-36{
  margin-left: 9rem !important;
}

.mt-40{
  margin-top: 10rem !important;
}

.mr-40{
  margin-right: 10rem !important;
}

.mb-40{
  margin-bottom: 10rem !important;
}

.ml-40{
  margin-left: 10rem !important;
}

.mt-48{
  margin-top: 12rem !important;
}

.mr-48{
  margin-right: 12rem !important;
}

.mb-48{
  margin-bottom: 12rem !important;
}

.ml-48{
  margin-left: 12rem !important;
}

.mt-56{
  margin-top: 14rem !important;
}

.mr-56{
  margin-right: 14rem !important;
}

.mb-56{
  margin-bottom: 14rem !important;
}

.ml-56{
  margin-left: 14rem !important;
}

.mt-64{
  margin-top: 16rem !important;
}

.mr-64{
  margin-right: 16rem !important;
}

.mb-64{
  margin-bottom: 16rem !important;
}

.ml-64{
  margin-left: 16rem !important;
}

.mt-auto{
  margin-top: auto !important;
}

.mr-auto{
  margin-right: auto !important;
}

.mb-auto{
  margin-bottom: auto !important;
}

.ml-auto{
  margin-left: auto !important;
}

.mt-px{
  margin-top: 1px !important;
}

.mr-px{
  margin-right: 1px !important;
}

.mb-px{
  margin-bottom: 1px !important;
}

.ml-px{
  margin-left: 1px !important;
}

.mt-clanScreenEdge{
  margin-top: 1.428rem !important;
}

.mr-clanScreenEdge{
  margin-right: 1.428rem !important;
}

.mb-clanScreenEdge{
  margin-bottom: 1.428rem !important;
}

.ml-clanScreenEdge{
  margin-left: 1.428rem !important;
}

.mt-clanFlowStepScreenEdge{
  margin-top: 1.563rem !important;
}

.mr-clanFlowStepScreenEdge{
  margin-right: 1.563rem !important;
}

.mb-clanFlowStepScreenEdge{
  margin-bottom: 1.563rem !important;
}

.ml-clanFlowStepScreenEdge{
  margin-left: 1.563rem !important;
}

.mt-clanCard{
  margin-top: 32rem !important;
}

.mr-clanCard{
  margin-right: 32rem !important;
}

.mb-clanCard{
  margin-bottom: 32rem !important;
}

.ml-clanCard{
  margin-left: 32rem !important;
}

.mt-clanBtn{
  margin-top: 2.5rem !important;
}

.mr-clanBtn{
  margin-right: 2.5rem !important;
}

.mb-clanBtn{
  margin-bottom: 2.5rem !important;
}

.ml-clanBtn{
  margin-left: 2.5rem !important;
}

.mt-clanBtnFat{
  margin-top: 3.5rem !important;
}

.mr-clanBtnFat{
  margin-right: 3.5rem !important;
}

.mb-clanBtnFat{
  margin-bottom: 3.5rem !important;
}

.ml-clanBtnFat{
  margin-left: 3.5rem !important;
}

.mt-clanAvatar{
  margin-top: 12.5rem !important;
}

.mr-clanAvatar{
  margin-right: 12.5rem !important;
}

.mb-clanAvatar{
  margin-bottom: 12.5rem !important;
}

.ml-clanAvatar{
  margin-left: 12.5rem !important;
}

.mt-clanProfileAvatar{
  margin-top: 8rem !important;
}

.mr-clanProfileAvatar{
  margin-right: 8rem !important;
}

.mb-clanProfileAvatar{
  margin-bottom: 8rem !important;
}

.ml-clanProfileAvatar{
  margin-left: 8rem !important;
}

.-mt-1{
  margin-top: -0.25rem !important;
}

.-mr-1{
  margin-right: -0.25rem !important;
}

.-mb-1{
  margin-bottom: -0.25rem !important;
}

.-ml-1{
  margin-left: -0.25rem !important;
}

.-mt-2{
  margin-top: -0.5rem !important;
}

.-mr-2{
  margin-right: -0.5rem !important;
}

.-mb-2{
  margin-bottom: -0.5rem !important;
}

.-ml-2{
  margin-left: -0.5rem !important;
}

.-mt-3{
  margin-top: -0.75rem !important;
}

.-mr-3{
  margin-right: -0.75rem !important;
}

.-mb-3{
  margin-bottom: -0.75rem !important;
}

.-ml-3{
  margin-left: -0.75rem !important;
}

.-mt-4{
  margin-top: -1rem !important;
}

.-mr-4{
  margin-right: -1rem !important;
}

.-mb-4{
  margin-bottom: -1rem !important;
}

.-ml-4{
  margin-left: -1rem !important;
}

.-mt-5{
  margin-top: -1.25rem !important;
}

.-mr-5{
  margin-right: -1.25rem !important;
}

.-mb-5{
  margin-bottom: -1.25rem !important;
}

.-ml-5{
  margin-left: -1.25rem !important;
}

.-mt-6{
  margin-top: -1.5rem !important;
}

.-mr-6{
  margin-right: -1.5rem !important;
}

.-mb-6{
  margin-bottom: -1.5rem !important;
}

.-ml-6{
  margin-left: -1.5rem !important;
}

.-mt-8{
  margin-top: -2rem !important;
}

.-mr-8{
  margin-right: -2rem !important;
}

.-mb-8{
  margin-bottom: -2rem !important;
}

.-ml-8{
  margin-left: -2rem !important;
}

.-mt-10{
  margin-top: -2.5rem !important;
}

.-mr-10{
  margin-right: -2.5rem !important;
}

.-mb-10{
  margin-bottom: -2.5rem !important;
}

.-ml-10{
  margin-left: -2.5rem !important;
}

.-mt-12{
  margin-top: -3rem !important;
}

.-mr-12{
  margin-right: -3rem !important;
}

.-mb-12{
  margin-bottom: -3rem !important;
}

.-ml-12{
  margin-left: -3rem !important;
}

.-mt-16{
  margin-top: -4rem !important;
}

.-mr-16{
  margin-right: -4rem !important;
}

.-mb-16{
  margin-bottom: -4rem !important;
}

.-ml-16{
  margin-left: -4rem !important;
}

.-mt-20{
  margin-top: -5rem !important;
}

.-mr-20{
  margin-right: -5rem !important;
}

.-mb-20{
  margin-bottom: -5rem !important;
}

.-ml-20{
  margin-left: -5rem !important;
}

.-mt-24{
  margin-top: -6rem !important;
}

.-mr-24{
  margin-right: -6rem !important;
}

.-mb-24{
  margin-bottom: -6rem !important;
}

.-ml-24{
  margin-left: -6rem !important;
}

.-mt-32{
  margin-top: -8rem !important;
}

.-mr-32{
  margin-right: -8rem !important;
}

.-mb-32{
  margin-bottom: -8rem !important;
}

.-ml-32{
  margin-left: -8rem !important;
}

.-mt-36{
  margin-top: -9rem !important;
}

.-mr-36{
  margin-right: -9rem !important;
}

.-mb-36{
  margin-bottom: -9rem !important;
}

.-ml-36{
  margin-left: -9rem !important;
}

.-mt-40{
  margin-top: -10rem !important;
}

.-mr-40{
  margin-right: -10rem !important;
}

.-mb-40{
  margin-bottom: -10rem !important;
}

.-ml-40{
  margin-left: -10rem !important;
}

.-mt-48{
  margin-top: -12rem !important;
}

.-mr-48{
  margin-right: -12rem !important;
}

.-mb-48{
  margin-bottom: -12rem !important;
}

.-ml-48{
  margin-left: -12rem !important;
}

.-mt-56{
  margin-top: -14rem !important;
}

.-mr-56{
  margin-right: -14rem !important;
}

.-mb-56{
  margin-bottom: -14rem !important;
}

.-ml-56{
  margin-left: -14rem !important;
}

.-mt-64{
  margin-top: -16rem !important;
}

.-mr-64{
  margin-right: -16rem !important;
}

.-mb-64{
  margin-bottom: -16rem !important;
}

.-ml-64{
  margin-left: -16rem !important;
}

.-mt-px{
  margin-top: -1px !important;
}

.-mr-px{
  margin-right: -1px !important;
}

.-mb-px{
  margin-bottom: -1px !important;
}

.-ml-px{
  margin-left: -1px !important;
}

.-mt-clanScreenEdge{
  margin-top: -1.428rem !important;
}

.-mr-clanScreenEdge{
  margin-right: -1.428rem !important;
}

.-mb-clanScreenEdge{
  margin-bottom: -1.428rem !important;
}

.-ml-clanScreenEdge{
  margin-left: -1.428rem !important;
}

.-mt-clanFlowStepScreenEdge{
  margin-top: -1.563rem !important;
}

.-mr-clanFlowStepScreenEdge{
  margin-right: -1.563rem !important;
}

.-mb-clanFlowStepScreenEdge{
  margin-bottom: -1.563rem !important;
}

.-ml-clanFlowStepScreenEdge{
  margin-left: -1.563rem !important;
}

.-mt-clanCard{
  margin-top: -32rem !important;
}

.-mr-clanCard{
  margin-right: -32rem !important;
}

.-mb-clanCard{
  margin-bottom: -32rem !important;
}

.-ml-clanCard{
  margin-left: -32rem !important;
}

.-mt-clanBtn{
  margin-top: -2.5rem !important;
}

.-mr-clanBtn{
  margin-right: -2.5rem !important;
}

.-mb-clanBtn{
  margin-bottom: -2.5rem !important;
}

.-ml-clanBtn{
  margin-left: -2.5rem !important;
}

.-mt-clanBtnFat{
  margin-top: -3.5rem !important;
}

.-mr-clanBtnFat{
  margin-right: -3.5rem !important;
}

.-mb-clanBtnFat{
  margin-bottom: -3.5rem !important;
}

.-ml-clanBtnFat{
  margin-left: -3.5rem !important;
}

.-mt-clanAvatar{
  margin-top: -12.5rem !important;
}

.-mr-clanAvatar{
  margin-right: -12.5rem !important;
}

.-mb-clanAvatar{
  margin-bottom: -12.5rem !important;
}

.-ml-clanAvatar{
  margin-left: -12.5rem !important;
}

.-mt-clanProfileAvatar{
  margin-top: -8rem !important;
}

.-mr-clanProfileAvatar{
  margin-right: -8rem !important;
}

.-mb-clanProfileAvatar{
  margin-bottom: -8rem !important;
}

.-ml-clanProfileAvatar{
  margin-left: -8rem !important;
}

.mt-clanFlowHeaderTop{
  margin-top: 10vh !important;
}

.mr-clanFlowHeaderTop{
  margin-right: 10vh !important;
}

.mb-clanFlowHeaderTop{
  margin-bottom: 10vh !important;
}

.ml-clanFlowHeaderTop{
  margin-left: 10vh !important;
}

.mt-clanFlowContentTop{
  margin-top: 10vh !important;
}

.mr-clanFlowContentTop{
  margin-right: 10vh !important;
}

.mb-clanFlowContentTop{
  margin-bottom: 10vh !important;
}

.ml-clanFlowContentTop{
  margin-left: 10vh !important;
}

.mt-clanRelativeFillScreen{
  margin-top: 0 -50vw !important;
}

.mr-clanRelativeFillScreen{
  margin-right: 0 -50vw !important;
}

.mb-clanRelativeFillScreen{
  margin-bottom: 0 -50vw !important;
}

.ml-clanRelativeFillScreen{
  margin-left: 0 -50vw !important;
}

.mt-clan25percent{
  margin-top: 25% !important;
}

.mr-clan25percent{
  margin-right: 25% !important;
}

.mb-clan25percent{
  margin-bottom: 25% !important;
}

.ml-clan25percent{
  margin-left: 25% !important;
}

.mt-clan30percent{
  margin-top: 30% !important;
}

.mr-clan30percent{
  margin-right: 30% !important;
}

.mb-clan30percent{
  margin-bottom: 30% !important;
}

.ml-clan30percent{
  margin-left: 30% !important;
}

.mt-clan50percent{
  margin-top: 50% !important;
}

.mr-clan50percent{
  margin-right: 50% !important;
}

.mb-clan50percent{
  margin-bottom: 50% !important;
}

.ml-clan50percent{
  margin-left: 50% !important;
}

.max-h-0{
  max-height: 0 !important;
}

.max-h-full{
  max-height: 100% !important;
}

.max-h-screen{
  max-height: 100vh !important;
}

.max-h-50vh{
  max-height: 50vh !important;
}

.max-h-clanSearchCard{
  max-height: 25rem !important;
}

.max-w-none{
  max-width: none !important;
}

.max-w-xs{
  max-width: 20rem !important;
}

.max-w-sm{
  max-width: 24rem !important;
}

.max-w-md{
  max-width: 28rem !important;
}

.max-w-lg{
  max-width: 32rem !important;
}

.max-w-xl{
  max-width: 36rem !important;
}

.max-w-2xl{
  max-width: 42rem !important;
}

.max-w-3xl{
  max-width: 48rem !important;
}

.max-w-4xl{
  max-width: 56rem !important;
}

.max-w-5xl{
  max-width: 64rem !important;
}

.max-w-6xl{
  max-width: 72rem !important;
}

.max-w-full{
  max-width: 100% !important;
}

.max-w-screen-sm{
  max-width: 640px !important;
}

.max-w-screen-md{
  max-width: 768px !important;
}

.max-w-screen-lg{
  max-width: 1024px !important;
}

.max-w-screen-xl{
  max-width: 1280px !important;
}

.max-w-screen-xs{
  max-width: 320px !important;
}

.min-h-0{
  min-height: 0 !important;
}

.min-h-full{
  min-height: 100% !important;
}

.min-h-screen{
  min-height: 100vh !important;
}

.min-h-clanCard{
  min-height: 50vh !important;
}

.min-h-clanListItem{
  min-height: 2.86rem !important;
}

.min-h-clanFixedBottom{
  min-height: 3.575rem !important;
}

.min-h-clanNotificationBody{
  min-height: 4rem !important;
}

.min-w-0{
  min-width: 0 !important;
}

.min-w-full{
  min-width: 100% !important;
}

.object-contain{
  object-fit: contain !important;
}

.object-cover{
  object-fit: cover !important;
}

.object-fill{
  object-fit: fill !important;
}

.object-none{
  object-fit: none !important;
}

.object-scale-down{
  object-fit: scale-down !important;
}

.object-bottom{
  object-position: bottom !important;
}

.object-center{
  object-position: center !important;
}

.object-left{
  object-position: left !important;
}

.object-left-bottom{
  object-position: left bottom !important;
}

.object-left-top{
  object-position: left top !important;
}

.object-right{
  object-position: right !important;
}

.object-right-bottom{
  object-position: right bottom !important;
}

.object-right-top{
  object-position: right top !important;
}

.object-top{
  object-position: top !important;
}

.opacity-0{
  opacity: 0 !important;
}

.opacity-25{
  opacity: 0.25 !important;
}

.opacity-50{
  opacity: 0.5 !important;
}

.opacity-75{
  opacity: 0.75 !important;
}

.opacity-100{
  opacity: 1 !important;
}

.outline-none{
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.outline-white{
  outline: 2px dotted white !important;
  outline-offset: 2px !important;
}

.outline-black{
  outline: 2px dotted black !important;
  outline-offset: 2px !important;
}

.overflow-auto{
  overflow: auto !important;
}

.overflow-hidden{
  overflow: hidden !important;
}

.overflow-visible{
  overflow: visible !important;
}

.overflow-scroll{
  overflow: scroll !important;
}

.overflow-x-auto{
  overflow-x: auto !important;
}

.overflow-y-auto{
  overflow-y: auto !important;
}

.overflow-x-hidden{
  overflow-x: hidden !important;
}

.overflow-y-hidden{
  overflow-y: hidden !important;
}

.overflow-x-visible{
  overflow-x: visible !important;
}

.overflow-y-visible{
  overflow-y: visible !important;
}

.overflow-x-scroll{
  overflow-x: scroll !important;
}

.overflow-y-scroll{
  overflow-y: scroll !important;
}

.scrolling-touch{
  -webkit-overflow-scrolling: touch !important;
}

.scrolling-auto{
  -webkit-overflow-scrolling: auto !important;
}

.overscroll-auto{
  -ms-scroll-chaining: chained !important;
      overscroll-behavior: auto !important;
}

.overscroll-contain{
  -ms-scroll-chaining: none !important;
      overscroll-behavior: contain !important;
}

.overscroll-none{
  -ms-scroll-chaining: none !important;
      overscroll-behavior: none !important;
}

.overscroll-y-auto{
  overscroll-behavior-y: auto !important;
}

.overscroll-y-contain{
  overscroll-behavior-y: contain !important;
}

.overscroll-y-none{
  overscroll-behavior-y: none !important;
}

.overscroll-x-auto{
  overscroll-behavior-x: auto !important;
}

.overscroll-x-contain{
  overscroll-behavior-x: contain !important;
}

.overscroll-x-none{
  overscroll-behavior-x: none !important;
}

.p-0{
  padding: 0 !important;
}

.p-1{
  padding: 0.25rem !important;
}

.p-2{
  padding: 0.5rem !important;
}

.p-3{
  padding: 0.75rem !important;
}

.p-4{
  padding: 1rem !important;
}

.p-5{
  padding: 1.25rem !important;
}

.p-6{
  padding: 1.5rem !important;
}

.p-8{
  padding: 2rem !important;
}

.p-10{
  padding: 2.5rem !important;
}

.p-12{
  padding: 3rem !important;
}

.p-16{
  padding: 4rem !important;
}

.p-20{
  padding: 5rem !important;
}

.p-24{
  padding: 6rem !important;
}

.p-32{
  padding: 8rem !important;
}

.p-36{
  padding: 9rem !important;
}

.p-40{
  padding: 10rem !important;
}

.p-48{
  padding: 12rem !important;
}

.p-56{
  padding: 14rem !important;
}

.p-64{
  padding: 16rem !important;
}

.p-px{
  padding: 1px !important;
}

.p-clanScreenEdge{
  padding: 1.428rem !important;
}

.p-clanFlowStepScreenEdge{
  padding: 1.563rem !important;
}

.p-clanCard{
  padding: 32rem !important;
}

.p-clanBtn{
  padding: 2.5rem !important;
}

.p-clanBtnFat{
  padding: 3.5rem !important;
}

.p-clanAvatar{
  padding: 12.5rem !important;
}

.p-clanProfileAvatar{
  padding: 8rem !important;
}

.py-0{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-1{
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.px-1{
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.py-2{
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.px-2{
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.py-3{
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.px-3{
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.py-4{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-4{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-5{
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.px-5{
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.py-6{
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.px-6{
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-8{
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-8{
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-10{
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.px-10{
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-12{
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.px-12{
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-16{
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.px-16{
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.py-20{
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.px-20{
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-24{
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.px-24{
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.py-32{
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.px-32{
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}

.py-36{
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.px-36{
  padding-left: 9rem !important;
  padding-right: 9rem !important;
}

.py-40{
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.px-40{
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.py-48{
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.px-48{
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}

.py-56{
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.px-56{
  padding-left: 14rem !important;
  padding-right: 14rem !important;
}

.py-64{
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.px-64{
  padding-left: 16rem !important;
  padding-right: 16rem !important;
}

.py-px{
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.px-px{
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.py-clanScreenEdge{
  padding-top: 1.428rem !important;
  padding-bottom: 1.428rem !important;
}

.px-clanScreenEdge{
  padding-left: 1.428rem !important;
  padding-right: 1.428rem !important;
}

.py-clanFlowStepScreenEdge{
  padding-top: 1.563rem !important;
  padding-bottom: 1.563rem !important;
}

.px-clanFlowStepScreenEdge{
  padding-left: 1.563rem !important;
  padding-right: 1.563rem !important;
}

.py-clanCard{
  padding-top: 32rem !important;
  padding-bottom: 32rem !important;
}

.px-clanCard{
  padding-left: 32rem !important;
  padding-right: 32rem !important;
}

.py-clanBtn{
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.px-clanBtn{
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-clanBtnFat{
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.px-clanBtnFat{
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.py-clanAvatar{
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important;
}

.px-clanAvatar{
  padding-left: 12.5rem !important;
  padding-right: 12.5rem !important;
}

.py-clanProfileAvatar{
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.px-clanProfileAvatar{
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}

.pt-0{
  padding-top: 0 !important;
}

.pr-0{
  padding-right: 0 !important;
}

.pb-0{
  padding-bottom: 0 !important;
}

.pl-0{
  padding-left: 0 !important;
}

.pt-1{
  padding-top: 0.25rem !important;
}

.pr-1{
  padding-right: 0.25rem !important;
}

.pb-1{
  padding-bottom: 0.25rem !important;
}

.pl-1{
  padding-left: 0.25rem !important;
}

.pt-2{
  padding-top: 0.5rem !important;
}

.pr-2{
  padding-right: 0.5rem !important;
}

.pb-2{
  padding-bottom: 0.5rem !important;
}

.pl-2{
  padding-left: 0.5rem !important;
}

.pt-3{
  padding-top: 0.75rem !important;
}

.pr-3{
  padding-right: 0.75rem !important;
}

.pb-3{
  padding-bottom: 0.75rem !important;
}

.pl-3{
  padding-left: 0.75rem !important;
}

.pt-4{
  padding-top: 1rem !important;
}

.pr-4{
  padding-right: 1rem !important;
}

.pb-4{
  padding-bottom: 1rem !important;
}

.pl-4{
  padding-left: 1rem !important;
}

.pt-5{
  padding-top: 1.25rem !important;
}

.pr-5{
  padding-right: 1.25rem !important;
}

.pb-5{
  padding-bottom: 1.25rem !important;
}

.pl-5{
  padding-left: 1.25rem !important;
}

.pt-6{
  padding-top: 1.5rem !important;
}

.pr-6{
  padding-right: 1.5rem !important;
}

.pb-6{
  padding-bottom: 1.5rem !important;
}

.pl-6{
  padding-left: 1.5rem !important;
}

.pt-8{
  padding-top: 2rem !important;
}

.pr-8{
  padding-right: 2rem !important;
}

.pb-8{
  padding-bottom: 2rem !important;
}

.pl-8{
  padding-left: 2rem !important;
}

.pt-10{
  padding-top: 2.5rem !important;
}

.pr-10{
  padding-right: 2.5rem !important;
}

.pb-10{
  padding-bottom: 2.5rem !important;
}

.pl-10{
  padding-left: 2.5rem !important;
}

.pt-12{
  padding-top: 3rem !important;
}

.pr-12{
  padding-right: 3rem !important;
}

.pb-12{
  padding-bottom: 3rem !important;
}

.pl-12{
  padding-left: 3rem !important;
}

.pt-16{
  padding-top: 4rem !important;
}

.pr-16{
  padding-right: 4rem !important;
}

.pb-16{
  padding-bottom: 4rem !important;
}

.pl-16{
  padding-left: 4rem !important;
}

.pt-20{
  padding-top: 5rem !important;
}

.pr-20{
  padding-right: 5rem !important;
}

.pb-20{
  padding-bottom: 5rem !important;
}

.pl-20{
  padding-left: 5rem !important;
}

.pt-24{
  padding-top: 6rem !important;
}

.pr-24{
  padding-right: 6rem !important;
}

.pb-24{
  padding-bottom: 6rem !important;
}

.pl-24{
  padding-left: 6rem !important;
}

.pt-32{
  padding-top: 8rem !important;
}

.pr-32{
  padding-right: 8rem !important;
}

.pb-32{
  padding-bottom: 8rem !important;
}

.pl-32{
  padding-left: 8rem !important;
}

.pt-36{
  padding-top: 9rem !important;
}

.pr-36{
  padding-right: 9rem !important;
}

.pb-36{
  padding-bottom: 9rem !important;
}

.pl-36{
  padding-left: 9rem !important;
}

.pt-40{
  padding-top: 10rem !important;
}

.pr-40{
  padding-right: 10rem !important;
}

.pb-40{
  padding-bottom: 10rem !important;
}

.pl-40{
  padding-left: 10rem !important;
}

.pt-48{
  padding-top: 12rem !important;
}

.pr-48{
  padding-right: 12rem !important;
}

.pb-48{
  padding-bottom: 12rem !important;
}

.pl-48{
  padding-left: 12rem !important;
}

.pt-56{
  padding-top: 14rem !important;
}

.pr-56{
  padding-right: 14rem !important;
}

.pb-56{
  padding-bottom: 14rem !important;
}

.pl-56{
  padding-left: 14rem !important;
}

.pt-64{
  padding-top: 16rem !important;
}

.pr-64{
  padding-right: 16rem !important;
}

.pb-64{
  padding-bottom: 16rem !important;
}

.pl-64{
  padding-left: 16rem !important;
}

.pt-px{
  padding-top: 1px !important;
}

.pr-px{
  padding-right: 1px !important;
}

.pb-px{
  padding-bottom: 1px !important;
}

.pl-px{
  padding-left: 1px !important;
}

.pt-clanScreenEdge{
  padding-top: 1.428rem !important;
}

.pr-clanScreenEdge{
  padding-right: 1.428rem !important;
}

.pb-clanScreenEdge{
  padding-bottom: 1.428rem !important;
}

.pl-clanScreenEdge{
  padding-left: 1.428rem !important;
}

.pt-clanFlowStepScreenEdge{
  padding-top: 1.563rem !important;
}

.pr-clanFlowStepScreenEdge{
  padding-right: 1.563rem !important;
}

.pb-clanFlowStepScreenEdge{
  padding-bottom: 1.563rem !important;
}

.pl-clanFlowStepScreenEdge{
  padding-left: 1.563rem !important;
}

.pt-clanCard{
  padding-top: 32rem !important;
}

.pr-clanCard{
  padding-right: 32rem !important;
}

.pb-clanCard{
  padding-bottom: 32rem !important;
}

.pl-clanCard{
  padding-left: 32rem !important;
}

.pt-clanBtn{
  padding-top: 2.5rem !important;
}

.pr-clanBtn{
  padding-right: 2.5rem !important;
}

.pb-clanBtn{
  padding-bottom: 2.5rem !important;
}

.pl-clanBtn{
  padding-left: 2.5rem !important;
}

.pt-clanBtnFat{
  padding-top: 3.5rem !important;
}

.pr-clanBtnFat{
  padding-right: 3.5rem !important;
}

.pb-clanBtnFat{
  padding-bottom: 3.5rem !important;
}

.pl-clanBtnFat{
  padding-left: 3.5rem !important;
}

.pt-clanAvatar{
  padding-top: 12.5rem !important;
}

.pr-clanAvatar{
  padding-right: 12.5rem !important;
}

.pb-clanAvatar{
  padding-bottom: 12.5rem !important;
}

.pl-clanAvatar{
  padding-left: 12.5rem !important;
}

.pt-clanProfileAvatar{
  padding-top: 8rem !important;
}

.pr-clanProfileAvatar{
  padding-right: 8rem !important;
}

.pb-clanProfileAvatar{
  padding-bottom: 8rem !important;
}

.pl-clanProfileAvatar{
  padding-left: 8rem !important;
}

.pointer-events-none{
  pointer-events: none !important;
}

.pointer-events-auto{
  pointer-events: auto !important;
}

.static{
  position: static !important;
}

.fixed{
  position: fixed !important;
}

.absolute{
  position: absolute !important;
}

.relative{
  position: relative !important;
}

.sticky{
  position: -webkit-sticky !important;
  position: sticky !important;
}

.inset-0{
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.inset-1{
  top: 1rem !important;
  right: 1rem !important;
  bottom: 1rem !important;
  left: 1rem !important;
}

.inset-auto{
  top: auto !important;
  right: auto !important;
  bottom: auto !important;
  left: auto !important;
}

.inset-55vw{
  top: 55vw !important;
  right: 55vw !important;
  bottom: 55vw !important;
  left: 55vw !important;
}

.-inset-55vw{
  top: -55vw !important;
  right: -55vw !important;
  bottom: -55vw !important;
  left: -55vw !important;
}

.inset-y-0{
  top: 0 !important;
  bottom: 0 !important;
}

.inset-x-0{
  right: 0 !important;
  left: 0 !important;
}

.inset-y-1{
  top: 1rem !important;
  bottom: 1rem !important;
}

.inset-x-1{
  right: 1rem !important;
  left: 1rem !important;
}

.inset-y-auto{
  top: auto !important;
  bottom: auto !important;
}

.inset-x-auto{
  right: auto !important;
  left: auto !important;
}

.inset-y-55vw{
  top: 55vw !important;
  bottom: 55vw !important;
}

.inset-x-55vw{
  right: 55vw !important;
  left: 55vw !important;
}

.-inset-y-55vw{
  top: -55vw !important;
  bottom: -55vw !important;
}

.-inset-x-55vw{
  right: -55vw !important;
  left: -55vw !important;
}

.top-0{
  top: 0 !important;
}

.right-0{
  right: 0 !important;
}

.bottom-0{
  bottom: 0 !important;
}

.left-0{
  left: 0 !important;
}

.top-1{
  top: 1rem !important;
}

.right-1{
  right: 1rem !important;
}

.bottom-1{
  bottom: 1rem !important;
}

.left-1{
  left: 1rem !important;
}

.top-auto{
  top: auto !important;
}

.right-auto{
  right: auto !important;
}

.bottom-auto{
  bottom: auto !important;
}

.left-auto{
  left: auto !important;
}

.top-55vw{
  top: 55vw !important;
}

.right-55vw{
  right: 55vw !important;
}

.bottom-55vw{
  bottom: 55vw !important;
}

.left-55vw{
  left: 55vw !important;
}

.-top-55vw{
  top: -55vw !important;
}

.-right-55vw{
  right: -55vw !important;
}

.-bottom-55vw{
  bottom: -55vw !important;
}

.-left-55vw{
  left: -55vw !important;
}

.top-100\%{
  top: 100% !important;
}

.bottom-100\%{
  bottom: 100% !important;
}

.resize-none{
  resize: none !important;
}

.resize-y{
  resize: vertical !important;
}

.resize-x{
  resize: horizontal !important;
}

.resize{
  resize: both !important;
}

.shadow-xs{
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
}

.shadow-sm{
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.shadow{
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.shadow-md{
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.shadow-lg{
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.shadow-xl{
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.shadow-2xl{
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}

.shadow-inner{
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
}

.shadow-outline{
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.shadow-none{
  box-shadow: none !important;
}

.shadow-clan{
  box-shadow: 0px 7px 10px rgba(212, 189, 189, 0.5) !important;
}

.shadow-clanLight{
  box-shadow: 0px 1px 4px rgba(212, 189, 189, 0.5) !important;
}

.fill-current{
  fill: currentColor !important;
}

.stroke-current{
  stroke: currentColor !important;
}

.stroke-0{
  stroke-width: 0 !important;
}

.stroke-1{
  stroke-width: 1 !important;
}

.stroke-2{
  stroke-width: 2 !important;
}

.table-auto{
  table-layout: auto !important;
}

.table-fixed{
  table-layout: fixed !important;
}

.text-left{
  text-align: left !important;
}

.text-center{
  text-align: center !important;
}

.text-right{
  text-align: right !important;
}

.text-justify{
  text-align: justify !important;
}

.text-transparent{
  color: transparent !important;
}

.text-current{
  color: currentColor !important;
}

.text-black{
  --text-opacity: 1 !important;
  color: #000 !important;
  color: rgba(0, 0, 0, var(--text-opacity)) !important;
}

.text-white{
  --text-opacity: 1 !important;
  color: #fff !important;
  color: rgba(255, 255, 255, var(--text-opacity)) !important;
}

.text-gray-100{
  --text-opacity: 1 !important;
  color: #f7fafc !important;
  color: rgba(247, 250, 252, var(--text-opacity)) !important;
}

.text-gray-200{
  --text-opacity: 1 !important;
  color: #edf2f7 !important;
  color: rgba(237, 242, 247, var(--text-opacity)) !important;
}

.text-gray-300{
  --text-opacity: 1 !important;
  color: #e2e8f0 !important;
  color: rgba(226, 232, 240, var(--text-opacity)) !important;
}

.text-gray-400{
  --text-opacity: 1 !important;
  color: #cbd5e0 !important;
  color: rgba(203, 213, 224, var(--text-opacity)) !important;
}

.text-gray-500{
  --text-opacity: 1 !important;
  color: #a0aec0 !important;
  color: rgba(160, 174, 192, var(--text-opacity)) !important;
}

.text-gray-600{
  --text-opacity: 1 !important;
  color: #718096 !important;
  color: rgba(113, 128, 150, var(--text-opacity)) !important;
}

.text-gray-700{
  --text-opacity: 1 !important;
  color: #4a5568 !important;
  color: rgba(74, 85, 104, var(--text-opacity)) !important;
}

.text-gray-800{
  --text-opacity: 1 !important;
  color: #2d3748 !important;
  color: rgba(45, 55, 72, var(--text-opacity)) !important;
}

.text-gray-900{
  --text-opacity: 1 !important;
  color: #1a202c !important;
  color: rgba(26, 32, 44, var(--text-opacity)) !important;
}

.text-red-100{
  --text-opacity: 1 !important;
  color: #fff5f5 !important;
  color: rgba(255, 245, 245, var(--text-opacity)) !important;
}

.text-red-200{
  --text-opacity: 1 !important;
  color: #fed7d7 !important;
  color: rgba(254, 215, 215, var(--text-opacity)) !important;
}

.text-red-300{
  --text-opacity: 1 !important;
  color: #feb2b2 !important;
  color: rgba(254, 178, 178, var(--text-opacity)) !important;
}

.text-red-400{
  --text-opacity: 1 !important;
  color: #fc8181 !important;
  color: rgba(252, 129, 129, var(--text-opacity)) !important;
}

.text-red-500{
  --text-opacity: 1 !important;
  color: #f56565 !important;
  color: rgba(245, 101, 101, var(--text-opacity)) !important;
}

.text-red-600{
  --text-opacity: 1 !important;
  color: #e53e3e !important;
  color: rgba(229, 62, 62, var(--text-opacity)) !important;
}

.text-red-700{
  --text-opacity: 1 !important;
  color: #c53030 !important;
  color: rgba(197, 48, 48, var(--text-opacity)) !important;
}

.text-red-800{
  --text-opacity: 1 !important;
  color: #9b2c2c !important;
  color: rgba(155, 44, 44, var(--text-opacity)) !important;
}

.text-red-900{
  --text-opacity: 1 !important;
  color: #742a2a !important;
  color: rgba(116, 42, 42, var(--text-opacity)) !important;
}

.text-orange-100{
  --text-opacity: 1 !important;
  color: #fffaf0 !important;
  color: rgba(255, 250, 240, var(--text-opacity)) !important;
}

.text-orange-200{
  --text-opacity: 1 !important;
  color: #feebc8 !important;
  color: rgba(254, 235, 200, var(--text-opacity)) !important;
}

.text-orange-300{
  --text-opacity: 1 !important;
  color: #fbd38d !important;
  color: rgba(251, 211, 141, var(--text-opacity)) !important;
}

.text-orange-400{
  --text-opacity: 1 !important;
  color: #f6ad55 !important;
  color: rgba(246, 173, 85, var(--text-opacity)) !important;
}

.text-orange-500{
  --text-opacity: 1 !important;
  color: #ed8936 !important;
  color: rgba(237, 137, 54, var(--text-opacity)) !important;
}

.text-orange-600{
  --text-opacity: 1 !important;
  color: #dd6b20 !important;
  color: rgba(221, 107, 32, var(--text-opacity)) !important;
}

.text-orange-700{
  --text-opacity: 1 !important;
  color: #c05621 !important;
  color: rgba(192, 86, 33, var(--text-opacity)) !important;
}

.text-orange-800{
  --text-opacity: 1 !important;
  color: #9c4221 !important;
  color: rgba(156, 66, 33, var(--text-opacity)) !important;
}

.text-orange-900{
  --text-opacity: 1 !important;
  color: #7b341e !important;
  color: rgba(123, 52, 30, var(--text-opacity)) !important;
}

.text-yellow-100{
  --text-opacity: 1 !important;
  color: #fffff0 !important;
  color: rgba(255, 255, 240, var(--text-opacity)) !important;
}

.text-yellow-200{
  --text-opacity: 1 !important;
  color: #fefcbf !important;
  color: rgba(254, 252, 191, var(--text-opacity)) !important;
}

.text-yellow-300{
  --text-opacity: 1 !important;
  color: #faf089 !important;
  color: rgba(250, 240, 137, var(--text-opacity)) !important;
}

.text-yellow-400{
  --text-opacity: 1 !important;
  color: #f6e05e !important;
  color: rgba(246, 224, 94, var(--text-opacity)) !important;
}

.text-yellow-500{
  --text-opacity: 1 !important;
  color: #ecc94b !important;
  color: rgba(236, 201, 75, var(--text-opacity)) !important;
}

.text-yellow-600{
  --text-opacity: 1 !important;
  color: #d69e2e !important;
  color: rgba(214, 158, 46, var(--text-opacity)) !important;
}

.text-yellow-700{
  --text-opacity: 1 !important;
  color: #b7791f !important;
  color: rgba(183, 121, 31, var(--text-opacity)) !important;
}

.text-yellow-800{
  --text-opacity: 1 !important;
  color: #975a16 !important;
  color: rgba(151, 90, 22, var(--text-opacity)) !important;
}

.text-yellow-900{
  --text-opacity: 1 !important;
  color: #744210 !important;
  color: rgba(116, 66, 16, var(--text-opacity)) !important;
}

.text-green-100{
  --text-opacity: 1 !important;
  color: #f0fff4 !important;
  color: rgba(240, 255, 244, var(--text-opacity)) !important;
}

.text-green-200{
  --text-opacity: 1 !important;
  color: #c6f6d5 !important;
  color: rgba(198, 246, 213, var(--text-opacity)) !important;
}

.text-green-300{
  --text-opacity: 1 !important;
  color: #9ae6b4 !important;
  color: rgba(154, 230, 180, var(--text-opacity)) !important;
}

.text-green-400{
  --text-opacity: 1 !important;
  color: #68d391 !important;
  color: rgba(104, 211, 145, var(--text-opacity)) !important;
}

.text-green-500{
  --text-opacity: 1 !important;
  color: #48bb78 !important;
  color: rgba(72, 187, 120, var(--text-opacity)) !important;
}

.text-green-600{
  --text-opacity: 1 !important;
  color: #38a169 !important;
  color: rgba(56, 161, 105, var(--text-opacity)) !important;
}

.text-green-700{
  --text-opacity: 1 !important;
  color: #2f855a !important;
  color: rgba(47, 133, 90, var(--text-opacity)) !important;
}

.text-green-800{
  --text-opacity: 1 !important;
  color: #276749 !important;
  color: rgba(39, 103, 73, var(--text-opacity)) !important;
}

.text-green-900{
  --text-opacity: 1 !important;
  color: #22543d !important;
  color: rgba(34, 84, 61, var(--text-opacity)) !important;
}

.text-teal-100{
  --text-opacity: 1 !important;
  color: #e6fffa !important;
  color: rgba(230, 255, 250, var(--text-opacity)) !important;
}

.text-teal-200{
  --text-opacity: 1 !important;
  color: #b2f5ea !important;
  color: rgba(178, 245, 234, var(--text-opacity)) !important;
}

.text-teal-300{
  --text-opacity: 1 !important;
  color: #81e6d9 !important;
  color: rgba(129, 230, 217, var(--text-opacity)) !important;
}

.text-teal-400{
  --text-opacity: 1 !important;
  color: #4fd1c5 !important;
  color: rgba(79, 209, 197, var(--text-opacity)) !important;
}

.text-teal-500{
  --text-opacity: 1 !important;
  color: #38b2ac !important;
  color: rgba(56, 178, 172, var(--text-opacity)) !important;
}

.text-teal-600{
  --text-opacity: 1 !important;
  color: #319795 !important;
  color: rgba(49, 151, 149, var(--text-opacity)) !important;
}

.text-teal-700{
  --text-opacity: 1 !important;
  color: #2c7a7b !important;
  color: rgba(44, 122, 123, var(--text-opacity)) !important;
}

.text-teal-800{
  --text-opacity: 1 !important;
  color: #285e61 !important;
  color: rgba(40, 94, 97, var(--text-opacity)) !important;
}

.text-teal-900{
  --text-opacity: 1 !important;
  color: #234e52 !important;
  color: rgba(35, 78, 82, var(--text-opacity)) !important;
}

.text-blue-100{
  --text-opacity: 1 !important;
  color: #ebf8ff !important;
  color: rgba(235, 248, 255, var(--text-opacity)) !important;
}

.text-blue-200{
  --text-opacity: 1 !important;
  color: #bee3f8 !important;
  color: rgba(190, 227, 248, var(--text-opacity)) !important;
}

.text-blue-300{
  --text-opacity: 1 !important;
  color: #90cdf4 !important;
  color: rgba(144, 205, 244, var(--text-opacity)) !important;
}

.text-blue-400{
  --text-opacity: 1 !important;
  color: #63b3ed !important;
  color: rgba(99, 179, 237, var(--text-opacity)) !important;
}

.text-blue-500{
  --text-opacity: 1 !important;
  color: #4299e1 !important;
  color: rgba(66, 153, 225, var(--text-opacity)) !important;
}

.text-blue-600{
  --text-opacity: 1 !important;
  color: #3182ce !important;
  color: rgba(49, 130, 206, var(--text-opacity)) !important;
}

.text-blue-700{
  --text-opacity: 1 !important;
  color: #2b6cb0 !important;
  color: rgba(43, 108, 176, var(--text-opacity)) !important;
}

.text-blue-800{
  --text-opacity: 1 !important;
  color: #2c5282 !important;
  color: rgba(44, 82, 130, var(--text-opacity)) !important;
}

.text-blue-900{
  --text-opacity: 1 !important;
  color: #2a4365 !important;
  color: rgba(42, 67, 101, var(--text-opacity)) !important;
}

.text-indigo-100{
  --text-opacity: 1 !important;
  color: #ebf4ff !important;
  color: rgba(235, 244, 255, var(--text-opacity)) !important;
}

.text-indigo-200{
  --text-opacity: 1 !important;
  color: #c3dafe !important;
  color: rgba(195, 218, 254, var(--text-opacity)) !important;
}

.text-indigo-300{
  --text-opacity: 1 !important;
  color: #a3bffa !important;
  color: rgba(163, 191, 250, var(--text-opacity)) !important;
}

.text-indigo-400{
  --text-opacity: 1 !important;
  color: #7f9cf5 !important;
  color: rgba(127, 156, 245, var(--text-opacity)) !important;
}

.text-indigo-500{
  --text-opacity: 1 !important;
  color: #667eea !important;
  color: rgba(102, 126, 234, var(--text-opacity)) !important;
}

.text-indigo-600{
  --text-opacity: 1 !important;
  color: #5a67d8 !important;
  color: rgba(90, 103, 216, var(--text-opacity)) !important;
}

.text-indigo-700{
  --text-opacity: 1 !important;
  color: #4c51bf !important;
  color: rgba(76, 81, 191, var(--text-opacity)) !important;
}

.text-indigo-800{
  --text-opacity: 1 !important;
  color: #434190 !important;
  color: rgba(67, 65, 144, var(--text-opacity)) !important;
}

.text-indigo-900{
  --text-opacity: 1 !important;
  color: #3c366b !important;
  color: rgba(60, 54, 107, var(--text-opacity)) !important;
}

.text-purple-100{
  --text-opacity: 1 !important;
  color: #faf5ff !important;
  color: rgba(250, 245, 255, var(--text-opacity)) !important;
}

.text-purple-200{
  --text-opacity: 1 !important;
  color: #e9d8fd !important;
  color: rgba(233, 216, 253, var(--text-opacity)) !important;
}

.text-purple-300{
  --text-opacity: 1 !important;
  color: #d6bcfa !important;
  color: rgba(214, 188, 250, var(--text-opacity)) !important;
}

.text-purple-400{
  --text-opacity: 1 !important;
  color: #b794f4 !important;
  color: rgba(183, 148, 244, var(--text-opacity)) !important;
}

.text-purple-500{
  --text-opacity: 1 !important;
  color: #9f7aea !important;
  color: rgba(159, 122, 234, var(--text-opacity)) !important;
}

.text-purple-600{
  --text-opacity: 1 !important;
  color: #805ad5 !important;
  color: rgba(128, 90, 213, var(--text-opacity)) !important;
}

.text-purple-700{
  --text-opacity: 1 !important;
  color: #6b46c1 !important;
  color: rgba(107, 70, 193, var(--text-opacity)) !important;
}

.text-purple-800{
  --text-opacity: 1 !important;
  color: #553c9a !important;
  color: rgba(85, 60, 154, var(--text-opacity)) !important;
}

.text-purple-900{
  --text-opacity: 1 !important;
  color: #44337a !important;
  color: rgba(68, 51, 122, var(--text-opacity)) !important;
}

.text-pink-100{
  --text-opacity: 1 !important;
  color: #fff5f7 !important;
  color: rgba(255, 245, 247, var(--text-opacity)) !important;
}

.text-pink-200{
  --text-opacity: 1 !important;
  color: #fed7e2 !important;
  color: rgba(254, 215, 226, var(--text-opacity)) !important;
}

.text-pink-300{
  --text-opacity: 1 !important;
  color: #fbb6ce !important;
  color: rgba(251, 182, 206, var(--text-opacity)) !important;
}

.text-pink-400{
  --text-opacity: 1 !important;
  color: #f687b3 !important;
  color: rgba(246, 135, 179, var(--text-opacity)) !important;
}

.text-pink-500{
  --text-opacity: 1 !important;
  color: #ed64a6 !important;
  color: rgba(237, 100, 166, var(--text-opacity)) !important;
}

.text-pink-600{
  --text-opacity: 1 !important;
  color: #d53f8c !important;
  color: rgba(213, 63, 140, var(--text-opacity)) !important;
}

.text-pink-700{
  --text-opacity: 1 !important;
  color: #b83280 !important;
  color: rgba(184, 50, 128, var(--text-opacity)) !important;
}

.text-pink-800{
  --text-opacity: 1 !important;
  color: #97266d !important;
  color: rgba(151, 38, 109, var(--text-opacity)) !important;
}

.text-pink-900{
  --text-opacity: 1 !important;
  color: #702459 !important;
  color: rgba(112, 36, 89, var(--text-opacity)) !important;
}

.text-clanGreen-100{
  --text-opacity: 1 !important;
  color: #45dd85 !important;
  color: rgba(69, 221, 133, var(--text-opacity)) !important;
}

.text-clanGray-100{
  --text-opacity: 1 !important;
  color: #fafafa !important;
  color: rgba(250, 250, 250, var(--text-opacity)) !important;
}

.text-clanGray-200{
  --text-opacity: 1 !important;
  color: #878396 !important;
  color: rgba(135, 131, 150, var(--text-opacity)) !important;
}

.text-clanGray-300{
  --text-opacity: 1 !important;
  color: #e0e0e0 !important;
  color: rgba(224, 224, 224, var(--text-opacity)) !important;
}

.text-clanYellow-100{
  --text-opacity: 1 !important;
  color: #fee661 !important;
  color: rgba(254, 230, 97, var(--text-opacity)) !important;
}

.text-clanRed-100{
  --text-opacity: 1 !important;
  color: #ff6471 !important;
  color: rgba(255, 100, 113, var(--text-opacity)) !important;
}

.text-opacity-0{
  --text-opacity: 0 !important;
}

.text-opacity-25{
  --text-opacity: 0.25 !important;
}

.text-opacity-50{
  --text-opacity: 0.5 !important;
}

.text-opacity-75{
  --text-opacity: 0.75 !important;
}

.text-opacity-100{
  --text-opacity: 1 !important;
}

.italic{
  font-style: italic !important;
}

.not-italic{
  font-style: normal !important;
}

.uppercase{
  text-transform: uppercase !important;
}

.lowercase{
  text-transform: lowercase !important;
}

.capitalize{
  text-transform: capitalize !important;
}

.normal-case{
  text-transform: none !important;
}

.underline{
  text-decoration: underline !important;
}

.line-through{
  text-decoration: line-through !important;
}

.no-underline{
  text-decoration: none !important;
}

.antialiased{
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.subpixel-antialiased{
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
}

.ordinal, .slashed-zero, .lining-nums, .oldstyle-nums, .proportional-nums, .tabular-nums, .diagonal-fractions, .stacked-fractions{
  --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/) !important;
  --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/) !important;
  --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/) !important;
  --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/) !important;
  --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/) !important;
  font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction) !important;
}

.normal-nums{
  font-variant-numeric: normal !important;
}

.ordinal{
  --font-variant-numeric-ordinal: ordinal !important;
}

.slashed-zero{
  --font-variant-numeric-slashed-zero: slashed-zero !important;
}

.lining-nums{
  --font-variant-numeric-figure: lining-nums !important;
}

.oldstyle-nums{
  --font-variant-numeric-figure: oldstyle-nums !important;
}

.proportional-nums{
  --font-variant-numeric-spacing: proportional-nums !important;
}

.tabular-nums{
  --font-variant-numeric-spacing: tabular-nums !important;
}

.diagonal-fractions{
  --font-variant-numeric-fraction: diagonal-fractions !important;
}

.stacked-fractions{
  --font-variant-numeric-fraction: stacked-fractions !important;
}

.tracking-tighter{
  letter-spacing: -0.05em !important;
}

.tracking-tight{
  letter-spacing: -0.025em !important;
}

.tracking-normal{
  letter-spacing: 0 !important;
}

.tracking-wide{
  letter-spacing: 0.025em !important;
}

.tracking-wider{
  letter-spacing: 0.05em !important;
}

.tracking-widest{
  letter-spacing: 0.1em !important;
}

.tracking-extrawide{
  letter-spacing: 0.5em !important;
}

.tracking-clanP{
  letter-spacing: 0.013rem !important;
}

.select-none{
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.select-text{
  -webkit-user-select: text !important;
      -ms-user-select: text !important;
          user-select: text !important;
}

.select-all{
  -webkit-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important;
}

.select-auto{
  -webkit-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.align-baseline{
  vertical-align: baseline !important;
}

.align-top{
  vertical-align: top !important;
}

.align-middle{
  vertical-align: middle !important;
}

.align-bottom{
  vertical-align: bottom !important;
}

.align-text-top{
  vertical-align: text-top !important;
}

.align-text-bottom{
  vertical-align: text-bottom !important;
}

.visible{
  visibility: visible !important;
}

.invisible{
  visibility: hidden !important;
}

.whitespace-normal{
  white-space: normal !important;
}

.whitespace-no-wrap{
  white-space: nowrap !important;
}

.whitespace-pre{
  white-space: pre !important;
}

.whitespace-pre-line{
  white-space: pre-line !important;
}

.whitespace-pre-wrap{
  white-space: pre-wrap !important;
}

.break-normal{
  word-wrap: normal !important;
  overflow-wrap: normal !important;
  word-break: normal !important;
}

.break-words{
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

.break-all{
  word-break: break-all !important;
}

.truncate{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.w-0{
  width: 0 !important;
}

.w-1{
  width: 0.25rem !important;
}

.w-2{
  width: 0.5rem !important;
}

.w-3{
  width: 0.75rem !important;
}

.w-4{
  width: 1rem !important;
}

.w-5{
  width: 1.25rem !important;
}

.w-6{
  width: 1.5rem !important;
}

.w-8{
  width: 2rem !important;
}

.w-10{
  width: 2.5rem !important;
}

.w-12{
  width: 3rem !important;
}

.w-16{
  width: 4rem !important;
}

.w-20{
  width: 5rem !important;
}

.w-24{
  width: 6rem !important;
}

.w-32{
  width: 8rem !important;
}

.w-36{
  width: 9rem !important;
}

.w-40{
  width: 10rem !important;
}

.w-48{
  width: 12rem !important;
}

.w-56{
  width: 14rem !important;
}

.w-64{
  width: 16rem !important;
}

.w-auto{
  width: auto !important;
}

.w-px{
  width: 1px !important;
}

.w-clanScreenEdge{
  width: 1.428rem !important;
}

.w-clanFlowStepScreenEdge{
  width: 1.563rem !important;
}

.w-clanCard{
  width: 32rem !important;
}

.w-clanBtn{
  width: 2.5rem !important;
}

.w-clanBtnFat{
  width: 3.5rem !important;
}

.w-clanAvatar{
  width: 12.5rem !important;
}

.w-clanProfileAvatar{
  width: 12.5rem !important;
}

.w-3\/4{
  width: 75% !important;
}

.w-4\/5{
  width: 80% !important;
}

.w-11\/12{
  width: 91.666667% !important;
}

.w-full{
  width: 100% !important;
}

.w-screen{
  width: 100vw !important;
}

.w-clanCardCircle{
  width: 12rem !important;
}

.w-clanProfileAvatarSmall{
  width: 2.15rem !important;
}

.w-clanFullScreenWidth{
  width: 100vw !important;
}

.z-0{
  z-index: 0 !important;
}

.z-10{
  z-index: 10 !important;
}

.z-20{
  z-index: 20 !important;
}

.z-30{
  z-index: 30 !important;
}

.z-40{
  z-index: 40 !important;
}

.z-50{
  z-index: 50 !important;
}

.z-100{
  z-index: 100 !important;
}

.z-auto{
  z-index: auto !important;
}

.-z-1{
  z-index: -1 !important;
}

.gap-0{
  grid-gap: 0 !important;
  gap: 0 !important;
}

.gap-1{
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important;
}

.gap-2{
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.gap-3{
  grid-gap: 0.75rem !important;
  gap: 0.75rem !important;
}

.gap-4{
  grid-gap: 1rem !important;
  gap: 1rem !important;
}

.gap-5{
  grid-gap: 1.25rem !important;
  gap: 1.25rem !important;
}

.gap-6{
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
}

.gap-8{
  grid-gap: 2rem !important;
  gap: 2rem !important;
}

.gap-10{
  grid-gap: 2.5rem !important;
  gap: 2.5rem !important;
}

.gap-12{
  grid-gap: 3rem !important;
  gap: 3rem !important;
}

.gap-16{
  grid-gap: 4rem !important;
  gap: 4rem !important;
}

.gap-20{
  grid-gap: 5rem !important;
  gap: 5rem !important;
}

.gap-24{
  grid-gap: 6rem !important;
  gap: 6rem !important;
}

.gap-32{
  grid-gap: 8rem !important;
  gap: 8rem !important;
}

.gap-36{
  grid-gap: 9rem !important;
  gap: 9rem !important;
}

.gap-40{
  grid-gap: 10rem !important;
  gap: 10rem !important;
}

.gap-48{
  grid-gap: 12rem !important;
  gap: 12rem !important;
}

.gap-56{
  grid-gap: 14rem !important;
  gap: 14rem !important;
}

.gap-64{
  grid-gap: 16rem !important;
  gap: 16rem !important;
}

.gap-px{
  grid-gap: 1px !important;
  gap: 1px !important;
}

.gap-clanScreenEdge{
  grid-gap: 1.428rem !important;
  gap: 1.428rem !important;
}

.gap-clanFlowStepScreenEdge{
  grid-gap: 1.563rem !important;
  gap: 1.563rem !important;
}

.gap-clanCard{
  grid-gap: 32rem !important;
  gap: 32rem !important;
}

.gap-clanBtn{
  grid-gap: 2.5rem !important;
  gap: 2.5rem !important;
}

.gap-clanBtnFat{
  grid-gap: 3.5rem !important;
  gap: 3.5rem !important;
}

.gap-clanAvatar{
  grid-gap: 12.5rem !important;
  gap: 12.5rem !important;
}

.gap-clanProfileAvatar{
  grid-gap: 8rem !important;
  gap: 8rem !important;
}

.col-gap-0{
  grid-column-gap: 0 !important;
  -webkit-column-gap: 0 !important;
          column-gap: 0 !important;
}

.col-gap-1{
  grid-column-gap: 0.25rem !important;
  -webkit-column-gap: 0.25rem !important;
          column-gap: 0.25rem !important;
}

.col-gap-2{
  grid-column-gap: 0.5rem !important;
  -webkit-column-gap: 0.5rem !important;
          column-gap: 0.5rem !important;
}

.col-gap-3{
  grid-column-gap: 0.75rem !important;
  -webkit-column-gap: 0.75rem !important;
          column-gap: 0.75rem !important;
}

.col-gap-4{
  grid-column-gap: 1rem !important;
  -webkit-column-gap: 1rem !important;
          column-gap: 1rem !important;
}

.col-gap-5{
  grid-column-gap: 1.25rem !important;
  -webkit-column-gap: 1.25rem !important;
          column-gap: 1.25rem !important;
}

.col-gap-6{
  grid-column-gap: 1.5rem !important;
  -webkit-column-gap: 1.5rem !important;
          column-gap: 1.5rem !important;
}

.col-gap-8{
  grid-column-gap: 2rem !important;
  -webkit-column-gap: 2rem !important;
          column-gap: 2rem !important;
}

.col-gap-10{
  grid-column-gap: 2.5rem !important;
  -webkit-column-gap: 2.5rem !important;
          column-gap: 2.5rem !important;
}

.col-gap-12{
  grid-column-gap: 3rem !important;
  -webkit-column-gap: 3rem !important;
          column-gap: 3rem !important;
}

.col-gap-16{
  grid-column-gap: 4rem !important;
  -webkit-column-gap: 4rem !important;
          column-gap: 4rem !important;
}

.col-gap-20{
  grid-column-gap: 5rem !important;
  -webkit-column-gap: 5rem !important;
          column-gap: 5rem !important;
}

.col-gap-24{
  grid-column-gap: 6rem !important;
  -webkit-column-gap: 6rem !important;
          column-gap: 6rem !important;
}

.col-gap-32{
  grid-column-gap: 8rem !important;
  -webkit-column-gap: 8rem !important;
          column-gap: 8rem !important;
}

.col-gap-36{
  grid-column-gap: 9rem !important;
  -webkit-column-gap: 9rem !important;
          column-gap: 9rem !important;
}

.col-gap-40{
  grid-column-gap: 10rem !important;
  -webkit-column-gap: 10rem !important;
          column-gap: 10rem !important;
}

.col-gap-48{
  grid-column-gap: 12rem !important;
  -webkit-column-gap: 12rem !important;
          column-gap: 12rem !important;
}

.col-gap-56{
  grid-column-gap: 14rem !important;
  -webkit-column-gap: 14rem !important;
          column-gap: 14rem !important;
}

.col-gap-64{
  grid-column-gap: 16rem !important;
  -webkit-column-gap: 16rem !important;
          column-gap: 16rem !important;
}

.col-gap-px{
  grid-column-gap: 1px !important;
  -webkit-column-gap: 1px !important;
          column-gap: 1px !important;
}

.col-gap-clanScreenEdge{
  grid-column-gap: 1.428rem !important;
  -webkit-column-gap: 1.428rem !important;
          column-gap: 1.428rem !important;
}

.col-gap-clanFlowStepScreenEdge{
  grid-column-gap: 1.563rem !important;
  -webkit-column-gap: 1.563rem !important;
          column-gap: 1.563rem !important;
}

.col-gap-clanCard{
  grid-column-gap: 32rem !important;
  -webkit-column-gap: 32rem !important;
          column-gap: 32rem !important;
}

.col-gap-clanBtn{
  grid-column-gap: 2.5rem !important;
  -webkit-column-gap: 2.5rem !important;
          column-gap: 2.5rem !important;
}

.col-gap-clanBtnFat{
  grid-column-gap: 3.5rem !important;
  -webkit-column-gap: 3.5rem !important;
          column-gap: 3.5rem !important;
}

.col-gap-clanAvatar{
  grid-column-gap: 12.5rem !important;
  -webkit-column-gap: 12.5rem !important;
          column-gap: 12.5rem !important;
}

.col-gap-clanProfileAvatar{
  grid-column-gap: 8rem !important;
  -webkit-column-gap: 8rem !important;
          column-gap: 8rem !important;
}

.gap-x-0{
  grid-column-gap: 0 !important;
  -webkit-column-gap: 0 !important;
          column-gap: 0 !important;
}

.gap-x-1{
  grid-column-gap: 0.25rem !important;
  -webkit-column-gap: 0.25rem !important;
          column-gap: 0.25rem !important;
}

.gap-x-2{
  grid-column-gap: 0.5rem !important;
  -webkit-column-gap: 0.5rem !important;
          column-gap: 0.5rem !important;
}

.gap-x-3{
  grid-column-gap: 0.75rem !important;
  -webkit-column-gap: 0.75rem !important;
          column-gap: 0.75rem !important;
}

.gap-x-4{
  grid-column-gap: 1rem !important;
  -webkit-column-gap: 1rem !important;
          column-gap: 1rem !important;
}

.gap-x-5{
  grid-column-gap: 1.25rem !important;
  -webkit-column-gap: 1.25rem !important;
          column-gap: 1.25rem !important;
}

.gap-x-6{
  grid-column-gap: 1.5rem !important;
  -webkit-column-gap: 1.5rem !important;
          column-gap: 1.5rem !important;
}

.gap-x-8{
  grid-column-gap: 2rem !important;
  -webkit-column-gap: 2rem !important;
          column-gap: 2rem !important;
}

.gap-x-10{
  grid-column-gap: 2.5rem !important;
  -webkit-column-gap: 2.5rem !important;
          column-gap: 2.5rem !important;
}

.gap-x-12{
  grid-column-gap: 3rem !important;
  -webkit-column-gap: 3rem !important;
          column-gap: 3rem !important;
}

.gap-x-16{
  grid-column-gap: 4rem !important;
  -webkit-column-gap: 4rem !important;
          column-gap: 4rem !important;
}

.gap-x-20{
  grid-column-gap: 5rem !important;
  -webkit-column-gap: 5rem !important;
          column-gap: 5rem !important;
}

.gap-x-24{
  grid-column-gap: 6rem !important;
  -webkit-column-gap: 6rem !important;
          column-gap: 6rem !important;
}

.gap-x-32{
  grid-column-gap: 8rem !important;
  -webkit-column-gap: 8rem !important;
          column-gap: 8rem !important;
}

.gap-x-36{
  grid-column-gap: 9rem !important;
  -webkit-column-gap: 9rem !important;
          column-gap: 9rem !important;
}

.gap-x-40{
  grid-column-gap: 10rem !important;
  -webkit-column-gap: 10rem !important;
          column-gap: 10rem !important;
}

.gap-x-48{
  grid-column-gap: 12rem !important;
  -webkit-column-gap: 12rem !important;
          column-gap: 12rem !important;
}

.gap-x-56{
  grid-column-gap: 14rem !important;
  -webkit-column-gap: 14rem !important;
          column-gap: 14rem !important;
}

.gap-x-64{
  grid-column-gap: 16rem !important;
  -webkit-column-gap: 16rem !important;
          column-gap: 16rem !important;
}

.gap-x-px{
  grid-column-gap: 1px !important;
  -webkit-column-gap: 1px !important;
          column-gap: 1px !important;
}

.gap-x-clanScreenEdge{
  grid-column-gap: 1.428rem !important;
  -webkit-column-gap: 1.428rem !important;
          column-gap: 1.428rem !important;
}

.gap-x-clanFlowStepScreenEdge{
  grid-column-gap: 1.563rem !important;
  -webkit-column-gap: 1.563rem !important;
          column-gap: 1.563rem !important;
}

.gap-x-clanCard{
  grid-column-gap: 32rem !important;
  -webkit-column-gap: 32rem !important;
          column-gap: 32rem !important;
}

.gap-x-clanBtn{
  grid-column-gap: 2.5rem !important;
  -webkit-column-gap: 2.5rem !important;
          column-gap: 2.5rem !important;
}

.gap-x-clanBtnFat{
  grid-column-gap: 3.5rem !important;
  -webkit-column-gap: 3.5rem !important;
          column-gap: 3.5rem !important;
}

.gap-x-clanAvatar{
  grid-column-gap: 12.5rem !important;
  -webkit-column-gap: 12.5rem !important;
          column-gap: 12.5rem !important;
}

.gap-x-clanProfileAvatar{
  grid-column-gap: 8rem !important;
  -webkit-column-gap: 8rem !important;
          column-gap: 8rem !important;
}

.row-gap-0{
  grid-row-gap: 0 !important;
  row-gap: 0 !important;
}

.row-gap-1{
  grid-row-gap: 0.25rem !important;
  row-gap: 0.25rem !important;
}

.row-gap-2{
  grid-row-gap: 0.5rem !important;
  row-gap: 0.5rem !important;
}

.row-gap-3{
  grid-row-gap: 0.75rem !important;
  row-gap: 0.75rem !important;
}

.row-gap-4{
  grid-row-gap: 1rem !important;
  row-gap: 1rem !important;
}

.row-gap-5{
  grid-row-gap: 1.25rem !important;
  row-gap: 1.25rem !important;
}

.row-gap-6{
  grid-row-gap: 1.5rem !important;
  row-gap: 1.5rem !important;
}

.row-gap-8{
  grid-row-gap: 2rem !important;
  row-gap: 2rem !important;
}

.row-gap-10{
  grid-row-gap: 2.5rem !important;
  row-gap: 2.5rem !important;
}

.row-gap-12{
  grid-row-gap: 3rem !important;
  row-gap: 3rem !important;
}

.row-gap-16{
  grid-row-gap: 4rem !important;
  row-gap: 4rem !important;
}

.row-gap-20{
  grid-row-gap: 5rem !important;
  row-gap: 5rem !important;
}

.row-gap-24{
  grid-row-gap: 6rem !important;
  row-gap: 6rem !important;
}

.row-gap-32{
  grid-row-gap: 8rem !important;
  row-gap: 8rem !important;
}

.row-gap-36{
  grid-row-gap: 9rem !important;
  row-gap: 9rem !important;
}

.row-gap-40{
  grid-row-gap: 10rem !important;
  row-gap: 10rem !important;
}

.row-gap-48{
  grid-row-gap: 12rem !important;
  row-gap: 12rem !important;
}

.row-gap-56{
  grid-row-gap: 14rem !important;
  row-gap: 14rem !important;
}

.row-gap-64{
  grid-row-gap: 16rem !important;
  row-gap: 16rem !important;
}

.row-gap-px{
  grid-row-gap: 1px !important;
  row-gap: 1px !important;
}

.row-gap-clanScreenEdge{
  grid-row-gap: 1.428rem !important;
  row-gap: 1.428rem !important;
}

.row-gap-clanFlowStepScreenEdge{
  grid-row-gap: 1.563rem !important;
  row-gap: 1.563rem !important;
}

.row-gap-clanCard{
  grid-row-gap: 32rem !important;
  row-gap: 32rem !important;
}

.row-gap-clanBtn{
  grid-row-gap: 2.5rem !important;
  row-gap: 2.5rem !important;
}

.row-gap-clanBtnFat{
  grid-row-gap: 3.5rem !important;
  row-gap: 3.5rem !important;
}

.row-gap-clanAvatar{
  grid-row-gap: 12.5rem !important;
  row-gap: 12.5rem !important;
}

.row-gap-clanProfileAvatar{
  grid-row-gap: 8rem !important;
  row-gap: 8rem !important;
}

.gap-y-0{
  grid-row-gap: 0 !important;
  row-gap: 0 !important;
}

.gap-y-1{
  grid-row-gap: 0.25rem !important;
  row-gap: 0.25rem !important;
}

.gap-y-2{
  grid-row-gap: 0.5rem !important;
  row-gap: 0.5rem !important;
}

.gap-y-3{
  grid-row-gap: 0.75rem !important;
  row-gap: 0.75rem !important;
}

.gap-y-4{
  grid-row-gap: 1rem !important;
  row-gap: 1rem !important;
}

.gap-y-5{
  grid-row-gap: 1.25rem !important;
  row-gap: 1.25rem !important;
}

.gap-y-6{
  grid-row-gap: 1.5rem !important;
  row-gap: 1.5rem !important;
}

.gap-y-8{
  grid-row-gap: 2rem !important;
  row-gap: 2rem !important;
}

.gap-y-10{
  grid-row-gap: 2.5rem !important;
  row-gap: 2.5rem !important;
}

.gap-y-12{
  grid-row-gap: 3rem !important;
  row-gap: 3rem !important;
}

.gap-y-16{
  grid-row-gap: 4rem !important;
  row-gap: 4rem !important;
}

.gap-y-20{
  grid-row-gap: 5rem !important;
  row-gap: 5rem !important;
}

.gap-y-24{
  grid-row-gap: 6rem !important;
  row-gap: 6rem !important;
}

.gap-y-32{
  grid-row-gap: 8rem !important;
  row-gap: 8rem !important;
}

.gap-y-36{
  grid-row-gap: 9rem !important;
  row-gap: 9rem !important;
}

.gap-y-40{
  grid-row-gap: 10rem !important;
  row-gap: 10rem !important;
}

.gap-y-48{
  grid-row-gap: 12rem !important;
  row-gap: 12rem !important;
}

.gap-y-56{
  grid-row-gap: 14rem !important;
  row-gap: 14rem !important;
}

.gap-y-64{
  grid-row-gap: 16rem !important;
  row-gap: 16rem !important;
}

.gap-y-px{
  grid-row-gap: 1px !important;
  row-gap: 1px !important;
}

.gap-y-clanScreenEdge{
  grid-row-gap: 1.428rem !important;
  row-gap: 1.428rem !important;
}

.gap-y-clanFlowStepScreenEdge{
  grid-row-gap: 1.563rem !important;
  row-gap: 1.563rem !important;
}

.gap-y-clanCard{
  grid-row-gap: 32rem !important;
  row-gap: 32rem !important;
}

.gap-y-clanBtn{
  grid-row-gap: 2.5rem !important;
  row-gap: 2.5rem !important;
}

.gap-y-clanBtnFat{
  grid-row-gap: 3.5rem !important;
  row-gap: 3.5rem !important;
}

.gap-y-clanAvatar{
  grid-row-gap: 12.5rem !important;
  row-gap: 12.5rem !important;
}

.gap-y-clanProfileAvatar{
  grid-row-gap: 8rem !important;
  row-gap: 8rem !important;
}

.grid-flow-row{
  grid-auto-flow: row !important;
}

.grid-flow-col{
  grid-auto-flow: column !important;
}

.grid-flow-row-dense{
  grid-auto-flow: row dense !important;
}

.grid-flow-col-dense{
  grid-auto-flow: column dense !important;
}

.grid-cols-1{
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.grid-cols-4{
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.grid-cols-5{
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.grid-cols-6{
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.grid-cols-7{
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.grid-cols-8{
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.grid-cols-9{
  grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.grid-cols-10{
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.grid-cols-11{
  grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
}

.grid-cols-12{
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.grid-cols-none{
  grid-template-columns: none !important;
}

.grid-cols-3auto-middle{
  grid-template-columns: 1fr minmax(0, auto) 1fr !important;
}

.auto-cols-auto{
  grid-auto-columns: auto !important;
}

.auto-cols-min{
  grid-auto-columns: -webkit-min-content !important;
  grid-auto-columns: min-content !important;
}

.auto-cols-max{
  grid-auto-columns: -webkit-max-content !important;
  grid-auto-columns: max-content !important;
}

.auto-cols-fr{
  grid-auto-columns: minmax(0, 1fr) !important;
}

.col-auto{
  grid-column: auto !important;
}

.col-span-1{
  grid-column: span 1 / span 1 !important;
}

.col-span-2{
  grid-column: span 2 / span 2 !important;
}

.col-span-3{
  grid-column: span 3 / span 3 !important;
}

.col-span-4{
  grid-column: span 4 / span 4 !important;
}

.col-span-5{
  grid-column: span 5 / span 5 !important;
}

.col-span-6{
  grid-column: span 6 / span 6 !important;
}

.col-span-7{
  grid-column: span 7 / span 7 !important;
}

.col-span-8{
  grid-column: span 8 / span 8 !important;
}

.col-span-9{
  grid-column: span 9 / span 9 !important;
}

.col-span-10{
  grid-column: span 10 / span 10 !important;
}

.col-span-11{
  grid-column: span 11 / span 11 !important;
}

.col-span-12{
  grid-column: span 12 / span 12 !important;
}

.col-span-full{
  grid-column: 1 / -1 !important;
}

.col-start-1{
  grid-column-start: 1 !important;
}

.col-start-2{
  grid-column-start: 2 !important;
}

.col-start-3{
  grid-column-start: 3 !important;
}

.col-start-4{
  grid-column-start: 4 !important;
}

.col-start-5{
  grid-column-start: 5 !important;
}

.col-start-6{
  grid-column-start: 6 !important;
}

.col-start-7{
  grid-column-start: 7 !important;
}

.col-start-8{
  grid-column-start: 8 !important;
}

.col-start-9{
  grid-column-start: 9 !important;
}

.col-start-10{
  grid-column-start: 10 !important;
}

.col-start-11{
  grid-column-start: 11 !important;
}

.col-start-12{
  grid-column-start: 12 !important;
}

.col-start-13{
  grid-column-start: 13 !important;
}

.col-start-auto{
  grid-column-start: auto !important;
}

.col-end-1{
  grid-column-end: 1 !important;
}

.col-end-2{
  grid-column-end: 2 !important;
}

.col-end-3{
  grid-column-end: 3 !important;
}

.col-end-4{
  grid-column-end: 4 !important;
}

.col-end-5{
  grid-column-end: 5 !important;
}

.col-end-6{
  grid-column-end: 6 !important;
}

.col-end-7{
  grid-column-end: 7 !important;
}

.col-end-8{
  grid-column-end: 8 !important;
}

.col-end-9{
  grid-column-end: 9 !important;
}

.col-end-10{
  grid-column-end: 10 !important;
}

.col-end-11{
  grid-column-end: 11 !important;
}

.col-end-12{
  grid-column-end: 12 !important;
}

.col-end-13{
  grid-column-end: 13 !important;
}

.col-end-auto{
  grid-column-end: auto !important;
}

.grid-rows-1{
  grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
}

.grid-rows-2{
  grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
}

.grid-rows-3{
  grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
}

.grid-rows-4{
  grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
}

.grid-rows-5{
  grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
}

.grid-rows-6{
  grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
}

.grid-rows-none{
  grid-template-rows: none !important;
}

.auto-rows-auto{
  grid-auto-rows: auto !important;
}

.auto-rows-min{
  grid-auto-rows: -webkit-min-content !important;
  grid-auto-rows: min-content !important;
}

.auto-rows-max{
  grid-auto-rows: -webkit-max-content !important;
  grid-auto-rows: max-content !important;
}

.auto-rows-fr{
  grid-auto-rows: minmax(0, 1fr) !important;
}

.row-auto{
  grid-row: auto !important;
}

.row-span-1{
  grid-row: span 1 / span 1 !important;
}

.row-span-2{
  grid-row: span 2 / span 2 !important;
}

.row-span-3{
  grid-row: span 3 / span 3 !important;
}

.row-span-4{
  grid-row: span 4 / span 4 !important;
}

.row-span-5{
  grid-row: span 5 / span 5 !important;
}

.row-span-6{
  grid-row: span 6 / span 6 !important;
}

.row-span-full{
  grid-row: 1 / -1 !important;
}

.row-start-1{
  grid-row-start: 1 !important;
}

.row-start-2{
  grid-row-start: 2 !important;
}

.row-start-3{
  grid-row-start: 3 !important;
}

.row-start-4{
  grid-row-start: 4 !important;
}

.row-start-5{
  grid-row-start: 5 !important;
}

.row-start-6{
  grid-row-start: 6 !important;
}

.row-start-7{
  grid-row-start: 7 !important;
}

.row-start-auto{
  grid-row-start: auto !important;
}

.row-end-1{
  grid-row-end: 1 !important;
}

.row-end-2{
  grid-row-end: 2 !important;
}

.row-end-3{
  grid-row-end: 3 !important;
}

.row-end-4{
  grid-row-end: 4 !important;
}

.row-end-5{
  grid-row-end: 5 !important;
}

.row-end-6{
  grid-row-end: 6 !important;
}

.row-end-7{
  grid-row-end: 7 !important;
}

.row-end-auto{
  grid-row-end: auto !important;
}

.transform{
  --transform-translate-x: 0 !important;
  --transform-translate-y: 0 !important;
  --transform-rotate: 0 !important;
  --transform-skew-x: 0 !important;
  --transform-skew-y: 0 !important;
  --transform-scale-x: 1 !important;
  --transform-scale-y: 1 !important;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
}

.transform-none{
  -webkit-transform: none !important;
          transform: none !important;
}

.origin-center{
  -webkit-transform-origin: center !important;
          transform-origin: center !important;
}

.origin-top{
  -webkit-transform-origin: top !important;
          transform-origin: top !important;
}

.origin-top-right{
  -webkit-transform-origin: top right !important;
          transform-origin: top right !important;
}

.origin-right{
  -webkit-transform-origin: right !important;
          transform-origin: right !important;
}

.origin-bottom-right{
  -webkit-transform-origin: bottom right !important;
          transform-origin: bottom right !important;
}

.origin-bottom{
  -webkit-transform-origin: bottom !important;
          transform-origin: bottom !important;
}

.origin-bottom-left{
  -webkit-transform-origin: bottom left !important;
          transform-origin: bottom left !important;
}

.origin-left{
  -webkit-transform-origin: left !important;
          transform-origin: left !important;
}

.origin-top-left{
  -webkit-transform-origin: top left !important;
          transform-origin: top left !important;
}

.scale-0{
  --transform-scale-x: 0 !important;
  --transform-scale-y: 0 !important;
}

.scale-50{
  --transform-scale-x: .5 !important;
  --transform-scale-y: .5 !important;
}

.scale-75{
  --transform-scale-x: .75 !important;
  --transform-scale-y: .75 !important;
}

.scale-90{
  --transform-scale-x: .9 !important;
  --transform-scale-y: .9 !important;
}

.scale-95{
  --transform-scale-x: .95 !important;
  --transform-scale-y: .95 !important;
}

.scale-100{
  --transform-scale-x: 1 !important;
  --transform-scale-y: 1 !important;
}

.scale-105{
  --transform-scale-x: 1.05 !important;
  --transform-scale-y: 1.05 !important;
}

.scale-110{
  --transform-scale-x: 1.1 !important;
  --transform-scale-y: 1.1 !important;
}

.scale-125{
  --transform-scale-x: 1.25 !important;
  --transform-scale-y: 1.25 !important;
}

.scale-150{
  --transform-scale-x: 1.5 !important;
  --transform-scale-y: 1.5 !important;
}

.scale-200{
  --transform-scale-x: 2 !important;
  --transform-scale-y: 2 !important;
}

.scale-x-0{
  --transform-scale-x: 0 !important;
}

.scale-x-50{
  --transform-scale-x: .5 !important;
}

.scale-x-75{
  --transform-scale-x: .75 !important;
}

.scale-x-90{
  --transform-scale-x: .9 !important;
}

.scale-x-95{
  --transform-scale-x: .95 !important;
}

.scale-x-100{
  --transform-scale-x: 1 !important;
}

.scale-x-105{
  --transform-scale-x: 1.05 !important;
}

.scale-x-110{
  --transform-scale-x: 1.1 !important;
}

.scale-x-125{
  --transform-scale-x: 1.25 !important;
}

.scale-x-150{
  --transform-scale-x: 1.5 !important;
}

.scale-x-200{
  --transform-scale-x: 2 !important;
}

.scale-y-0{
  --transform-scale-y: 0 !important;
}

.scale-y-50{
  --transform-scale-y: .5 !important;
}

.scale-y-75{
  --transform-scale-y: .75 !important;
}

.scale-y-90{
  --transform-scale-y: .9 !important;
}

.scale-y-95{
  --transform-scale-y: .95 !important;
}

.scale-y-100{
  --transform-scale-y: 1 !important;
}

.scale-y-105{
  --transform-scale-y: 1.05 !important;
}

.scale-y-110{
  --transform-scale-y: 1.1 !important;
}

.scale-y-125{
  --transform-scale-y: 1.25 !important;
}

.scale-y-150{
  --transform-scale-y: 1.5 !important;
}

.scale-y-200{
  --transform-scale-y: 2 !important;
}

.rotate-0{
  --transform-rotate: 0 !important;
}

.rotate-1{
  --transform-rotate: 1deg !important;
}

.rotate-2{
  --transform-rotate: 2deg !important;
}

.rotate-3{
  --transform-rotate: 3deg !important;
}

.rotate-6{
  --transform-rotate: 6deg !important;
}

.rotate-12{
  --transform-rotate: 12deg !important;
}

.rotate-45{
  --transform-rotate: 45deg !important;
}

.rotate-90{
  --transform-rotate: 90deg !important;
}

.rotate-180{
  --transform-rotate: 180deg !important;
}

.-rotate-180{
  --transform-rotate: -180deg !important;
}

.-rotate-90{
  --transform-rotate: -90deg !important;
}

.-rotate-45{
  --transform-rotate: -45deg !important;
}

.-rotate-12{
  --transform-rotate: -12deg !important;
}

.-rotate-6{
  --transform-rotate: -6deg !important;
}

.-rotate-3{
  --transform-rotate: -3deg !important;
}

.-rotate-2{
  --transform-rotate: -2deg !important;
}

.-rotate-1{
  --transform-rotate: -1deg !important;
}

.translate-x-0{
  --transform-translate-x: 0 !important;
}

.translate-x-1{
  --transform-translate-x: 0.25rem !important;
}

.translate-x-2{
  --transform-translate-x: 0.5rem !important;
}

.translate-x-3{
  --transform-translate-x: 0.75rem !important;
}

.translate-x-4{
  --transform-translate-x: 1rem !important;
}

.translate-x-5{
  --transform-translate-x: 1.25rem !important;
}

.translate-x-6{
  --transform-translate-x: 1.5rem !important;
}

.translate-x-8{
  --transform-translate-x: 2rem !important;
}

.translate-x-10{
  --transform-translate-x: 2.5rem !important;
}

.translate-x-12{
  --transform-translate-x: 3rem !important;
}

.translate-x-16{
  --transform-translate-x: 4rem !important;
}

.translate-x-20{
  --transform-translate-x: 5rem !important;
}

.translate-x-24{
  --transform-translate-x: 6rem !important;
}

.translate-x-32{
  --transform-translate-x: 8rem !important;
}

.translate-x-36{
  --transform-translate-x: 9rem !important;
}

.translate-x-40{
  --transform-translate-x: 10rem !important;
}

.translate-x-48{
  --transform-translate-x: 12rem !important;
}

.translate-x-56{
  --transform-translate-x: 14rem !important;
}

.translate-x-64{
  --transform-translate-x: 16rem !important;
}

.translate-x-px{
  --transform-translate-x: 1px !important;
}

.translate-x-clanScreenEdge{
  --transform-translate-x: 1.428rem !important;
}

.translate-x-clanFlowStepScreenEdge{
  --transform-translate-x: 1.563rem !important;
}

.translate-x-clanCard{
  --transform-translate-x: 32rem !important;
}

.translate-x-clanBtn{
  --transform-translate-x: 2.5rem !important;
}

.translate-x-clanBtnFat{
  --transform-translate-x: 3.5rem !important;
}

.translate-x-clanAvatar{
  --transform-translate-x: 12.5rem !important;
}

.translate-x-clanProfileAvatar{
  --transform-translate-x: 8rem !important;
}

.-translate-x-1{
  --transform-translate-x: -0.25rem !important;
}

.-translate-x-2{
  --transform-translate-x: -0.5rem !important;
}

.-translate-x-3{
  --transform-translate-x: -0.75rem !important;
}

.-translate-x-4{
  --transform-translate-x: -1rem !important;
}

.-translate-x-5{
  --transform-translate-x: -1.25rem !important;
}

.-translate-x-6{
  --transform-translate-x: -1.5rem !important;
}

.-translate-x-8{
  --transform-translate-x: -2rem !important;
}

.-translate-x-10{
  --transform-translate-x: -2.5rem !important;
}

.-translate-x-12{
  --transform-translate-x: -3rem !important;
}

.-translate-x-16{
  --transform-translate-x: -4rem !important;
}

.-translate-x-20{
  --transform-translate-x: -5rem !important;
}

.-translate-x-24{
  --transform-translate-x: -6rem !important;
}

.-translate-x-32{
  --transform-translate-x: -8rem !important;
}

.-translate-x-36{
  --transform-translate-x: -9rem !important;
}

.-translate-x-40{
  --transform-translate-x: -10rem !important;
}

.-translate-x-48{
  --transform-translate-x: -12rem !important;
}

.-translate-x-56{
  --transform-translate-x: -14rem !important;
}

.-translate-x-64{
  --transform-translate-x: -16rem !important;
}

.-translate-x-px{
  --transform-translate-x: -1px !important;
}

.-translate-x-clanScreenEdge{
  --transform-translate-x: -1.428rem !important;
}

.-translate-x-clanFlowStepScreenEdge{
  --transform-translate-x: -1.563rem !important;
}

.-translate-x-clanCard{
  --transform-translate-x: -32rem !important;
}

.-translate-x-clanBtn{
  --transform-translate-x: -2.5rem !important;
}

.-translate-x-clanBtnFat{
  --transform-translate-x: -3.5rem !important;
}

.-translate-x-clanAvatar{
  --transform-translate-x: -12.5rem !important;
}

.-translate-x-clanProfileAvatar{
  --transform-translate-x: -8rem !important;
}

.-translate-x-full{
  --transform-translate-x: -100% !important;
}

.translate-x-full{
  --transform-translate-x: 100% !important;
}

.translate-y-0{
  --transform-translate-y: 0 !important;
}

.translate-y-1{
  --transform-translate-y: 0.25rem !important;
}

.translate-y-2{
  --transform-translate-y: 0.5rem !important;
}

.translate-y-3{
  --transform-translate-y: 0.75rem !important;
}

.translate-y-4{
  --transform-translate-y: 1rem !important;
}

.translate-y-5{
  --transform-translate-y: 1.25rem !important;
}

.translate-y-6{
  --transform-translate-y: 1.5rem !important;
}

.translate-y-8{
  --transform-translate-y: 2rem !important;
}

.translate-y-10{
  --transform-translate-y: 2.5rem !important;
}

.translate-y-12{
  --transform-translate-y: 3rem !important;
}

.translate-y-16{
  --transform-translate-y: 4rem !important;
}

.translate-y-20{
  --transform-translate-y: 5rem !important;
}

.translate-y-24{
  --transform-translate-y: 6rem !important;
}

.translate-y-32{
  --transform-translate-y: 8rem !important;
}

.translate-y-36{
  --transform-translate-y: 9rem !important;
}

.translate-y-40{
  --transform-translate-y: 10rem !important;
}

.translate-y-48{
  --transform-translate-y: 12rem !important;
}

.translate-y-56{
  --transform-translate-y: 14rem !important;
}

.translate-y-64{
  --transform-translate-y: 16rem !important;
}

.translate-y-px{
  --transform-translate-y: 1px !important;
}

.translate-y-clanScreenEdge{
  --transform-translate-y: 1.428rem !important;
}

.translate-y-clanFlowStepScreenEdge{
  --transform-translate-y: 1.563rem !important;
}

.translate-y-clanCard{
  --transform-translate-y: 32rem !important;
}

.translate-y-clanBtn{
  --transform-translate-y: 2.5rem !important;
}

.translate-y-clanBtnFat{
  --transform-translate-y: 3.5rem !important;
}

.translate-y-clanAvatar{
  --transform-translate-y: 12.5rem !important;
}

.translate-y-clanProfileAvatar{
  --transform-translate-y: 8rem !important;
}

.-translate-y-1{
  --transform-translate-y: -0.25rem !important;
}

.-translate-y-2{
  --transform-translate-y: -0.5rem !important;
}

.-translate-y-3{
  --transform-translate-y: -0.75rem !important;
}

.-translate-y-4{
  --transform-translate-y: -1rem !important;
}

.-translate-y-5{
  --transform-translate-y: -1.25rem !important;
}

.-translate-y-6{
  --transform-translate-y: -1.5rem !important;
}

.-translate-y-8{
  --transform-translate-y: -2rem !important;
}

.-translate-y-10{
  --transform-translate-y: -2.5rem !important;
}

.-translate-y-12{
  --transform-translate-y: -3rem !important;
}

.-translate-y-16{
  --transform-translate-y: -4rem !important;
}

.-translate-y-20{
  --transform-translate-y: -5rem !important;
}

.-translate-y-24{
  --transform-translate-y: -6rem !important;
}

.-translate-y-32{
  --transform-translate-y: -8rem !important;
}

.-translate-y-36{
  --transform-translate-y: -9rem !important;
}

.-translate-y-40{
  --transform-translate-y: -10rem !important;
}

.-translate-y-48{
  --transform-translate-y: -12rem !important;
}

.-translate-y-56{
  --transform-translate-y: -14rem !important;
}

.-translate-y-64{
  --transform-translate-y: -16rem !important;
}

.-translate-y-px{
  --transform-translate-y: -1px !important;
}

.-translate-y-clanScreenEdge{
  --transform-translate-y: -1.428rem !important;
}

.-translate-y-clanFlowStepScreenEdge{
  --transform-translate-y: -1.563rem !important;
}

.-translate-y-clanCard{
  --transform-translate-y: -32rem !important;
}

.-translate-y-clanBtn{
  --transform-translate-y: -2.5rem !important;
}

.-translate-y-clanBtnFat{
  --transform-translate-y: -3.5rem !important;
}

.-translate-y-clanAvatar{
  --transform-translate-y: -12.5rem !important;
}

.-translate-y-clanProfileAvatar{
  --transform-translate-y: -8rem !important;
}

.-translate-y-full{
  --transform-translate-y: -100% !important;
}

.translate-y-full{
  --transform-translate-y: 100% !important;
}

.skew-x-0{
  --transform-skew-x: 0 !important;
}

.skew-x-1{
  --transform-skew-x: 1deg !important;
}

.skew-x-2{
  --transform-skew-x: 2deg !important;
}

.skew-x-3{
  --transform-skew-x: 3deg !important;
}

.skew-x-6{
  --transform-skew-x: 6deg !important;
}

.skew-x-12{
  --transform-skew-x: 12deg !important;
}

.-skew-x-12{
  --transform-skew-x: -12deg !important;
}

.-skew-x-6{
  --transform-skew-x: -6deg !important;
}

.-skew-x-3{
  --transform-skew-x: -3deg !important;
}

.-skew-x-2{
  --transform-skew-x: -2deg !important;
}

.-skew-x-1{
  --transform-skew-x: -1deg !important;
}

.skew-y-0{
  --transform-skew-y: 0 !important;
}

.skew-y-1{
  --transform-skew-y: 1deg !important;
}

.skew-y-2{
  --transform-skew-y: 2deg !important;
}

.skew-y-3{
  --transform-skew-y: 3deg !important;
}

.skew-y-6{
  --transform-skew-y: 6deg !important;
}

.skew-y-12{
  --transform-skew-y: 12deg !important;
}

.-skew-y-12{
  --transform-skew-y: -12deg !important;
}

.-skew-y-6{
  --transform-skew-y: -6deg !important;
}

.-skew-y-3{
  --transform-skew-y: -3deg !important;
}

.-skew-y-2{
  --transform-skew-y: -2deg !important;
}

.-skew-y-1{
  --transform-skew-y: -1deg !important;
}

.transition-none{
  transition-property: none !important;
}

.transition-all{
  transition-property: all !important;
}

.transition{
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform !important;
}

.transition-colors{
  transition-property: background-color, border-color, color, fill, stroke !important;
}

.transition-opacity{
  transition-property: opacity !important;
}

.transition-shadow{
  transition-property: box-shadow !important;
}

.transition-transform{
  transition-property: -webkit-transform !important;
  transition-property: transform !important;
  transition-property: transform, -webkit-transform !important;
}

.ease-linear{
  transition-timing-function: linear !important;
}

.ease-in{
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.ease-out{
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.ease-in-out{
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.duration-75{
  transition-duration: 75ms !important;
}

.duration-100{
  transition-duration: 100ms !important;
}

.duration-150{
  transition-duration: 150ms !important;
}

.duration-200{
  transition-duration: 200ms !important;
}

.duration-300{
  transition-duration: 300ms !important;
}

.duration-500{
  transition-duration: 500ms !important;
}

.duration-700{
  transition-duration: 700ms !important;
}

.duration-1000{
  transition-duration: 1000ms !important;
}

.delay-75{
  transition-delay: 75ms !important;
}

.delay-100{
  transition-delay: 100ms !important;
}

.delay-150{
  transition-delay: 150ms !important;
}

.delay-200{
  transition-delay: 200ms !important;
}

.delay-300{
  transition-delay: 300ms !important;
}

.delay-500{
  transition-delay: 500ms !important;
}

.delay-700{
  transition-delay: 700ms !important;
}

.delay-1000{
  transition-delay: 1000ms !important;
}

@-webkit-keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse{
  50%{
    opacity: .5;
  }
}

@keyframes pulse{
  50%{
    opacity: .5;
  }
}

@-webkit-keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.animate-none{
  -webkit-animation: none !important;
          animation: none !important;
}

.animate-spin{
  -webkit-animation: spin 1s linear infinite !important;
          animation: spin 1s linear infinite !important;
}

.animate-ping{
  -webkit-animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
          animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
}

.animate-pulse{
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
          animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}

.animate-bounce{
  -webkit-animation: bounce 1s infinite !important;
          animation: bounce 1s infinite !important;
}

.attr-content{
  content: attr(title) !important;
  position: absolute !important;
  top: -2.5rem !important;
  right: -25% !important;
  background-color: black !important;
  white-space: normal !important;
  -webkit-transform: translateY(5rem) !important;
          transform: translateY(5rem) !important;
  padding: 0.25rem !important;
  border-radius: 0.5rem !important;
  color: white !important;
}

.after\:attr-content::after{
  content: attr(title) !important;
  position: absolute !important;
  top: -2.5rem !important;
  right: -25% !important;
  background-color: black !important;
  white-space: normal !important;
  -webkit-transform: translateY(5rem) !important;
          transform: translateY(5rem) !important;
  padding: 0.25rem !important;
  border-radius: 0.5rem !important;
  color: white !important;
}

/* Add any custom utilities to the end of your CSS file */

/* Cleanbeat Theme file, fonts, ionic variables etc goes here */

@import "./colors.css";

@import "./fonts.css";

@import "./variables.css";

html {
  --thumbBG: rgb(135 131 150 / 50%);
}

/* Works on Firefox */

* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) white;
}

/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 20px;
  border: 3px solid white;
}

/* All font sizes are using rem. changing them here scales the entire app */

:root {
  font-size: 14px;
}

.info-card .popover-content {
  width: 100% !important;
  left: unset !important;
  top: unset !important;
  position: relative;
}

.info-card .popover-viewport {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.info-card .popover-wrapper {
  margin-top: auto;
  margin-bottom: 2rem;
  width: 90%;
  max-width: 500px;
}

/* For reactions popover */

.reactions-popover .popover-content {
  overflow: visible;
  background: #e0e0e0 !important;
  padding: 0.5rem;
  border-radius: 9999px;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}

/* For date time picker */

.picker-button {
  color: black !important;
}

.picker-opt-selected {
  color: #45dd85 !important;
  font-weight: 800 !important;
}

.tall-modal {
  --min-height: 80%;
}

.transparent-modal .modal-wrapper{
  background: transparent;
}

.fullpage-modal {
  --min-height: 100%;
  --min-width: 100%;
}

p {
  font-size: 1rem;
  line-height: 1.286rem;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

h1 {
  font-weight: 800;
  font-family: Gilroy;
  font-size: 3.214rem;
  line-height: 3.571rem;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

h2 {
  font-weight: 800;
  font-family: Gilroy;
  font-size: 1.714rem;
  line-height: 2rem;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

h3 {
  font-weight: 800;
  font-family: Gilroy;
  font-size: 1.286rem;
  line-height: 1.571rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

h4 {
  font-weight: 800;
  font-family: Gilroy;
  font-size: 1rem;
  line-height: 1.286rem;
  margin-top: 0;
  margin-bottom: 0;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

h5 {
  font-weight: 300;
  font-size: 0.8571rem;
  line-height: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

h6 {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.286rem;
  margin-top: 0;
  margin-bottom: 0;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.tab-button {
  --bg-opacity: 1;
  background-color: #fafafa;
  background-color: rgba(250, 250, 250, var(--bg-opacity));
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 3rem;
  height: 3rem;
}

.tab-button-small {
  --bg-opacity: 1;
  background-color: #fafafa;
  background-color: rgba(250, 250, 250, var(--bg-opacity));
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 2rem;
  height: 2rem;
}

.clan-list-item {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  padding: 0.75rem;
  min-height: 2.86rem;
  text-align: center;
  vertical-align: middle;
  --bg-opacity: 1;
  background-color: #fafafa;
  background-color: rgba(250, 250, 250, var(--bg-opacity));
  border-radius: 1.78rem;
}

VideoContent {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

VideoContent iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 640px){
}

@media (min-width: 768px){
}

@media (min-width: 1024px){
}

@media (min-width: 1280px){
}

@media (min-width: 320px){
}
